import React, { useState, useEffect } from 'react';
import BlogsCard from '../Components/BlogsCard';
import { Container, Row, Col, Alert, Button} from 'react-bootstrap';
import NoContent from '../Components/NoContent';
import axios from 'axios';
import { getBlogs } from '../api';
import { useNavigate } from 'react-router-dom';

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [showProfileAlert, setShowProfileAlert] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {

    // Function to check if the user's profile is complete
    const checkUserProfile = () => {
      const userProfile = JSON.parse(localStorage.getItem("userprofile"));

      if (userProfile) {
        const { _sourceObject: { images, institute_name, qualification } = {} } = userProfile;
        if (images?.length && institute_name && qualification) {
          setIsProfileComplete(true);
          setShowProfileAlert(false); 
        } else {
          setIsProfileComplete(false);
          setShowProfileAlert(true); 
        }
      } else {
        setIsProfileComplete(false);
        setShowProfileAlert(true); 
      }
    };

    // STEP 2
    const fetchBlogs = async () => {
      const result = await getBlogs()
      if (!result) {
        console.log("api errro > in blogs.jsx")
      }
      localStorage.setItem("blogs", JSON.stringify(result))
      setBlogs(result)
    }

    // STEP 1  
    const local_blogs = localStorage.getItem("blogs")
    if (!local_blogs) {
      fetchBlogs()
    }
    else{
      setBlogs(JSON.parse(local_blogs))
    }
    checkUserProfile();
  }, []);

    // Function to redirect to profile page
    const handleProfileRedirect = () => {
      navigate('/profile');  // Redirect to the profile page
    };

  return (
    <>
    <Container className="my-5">
    {showProfileAlert && (
        <Alert variant="danger fw-bold text-center">
          Please complete your profile to unlock all the blogs.
        </Alert>
      )}

      {blogs.length > 0 ? (
        <Row>
          {blogs.map((blog, index) => {
            // Determine if the blog should be blurred
            const showBlog = isProfileComplete || index < 8;

            return (
              <Col key={index} lg={3} md={4} sm={6} xs={12} className="d-flex">
                <div style={{ filter: showBlog ? 'none' : 'blur(5px)' }}>
                  <BlogsCard
                    article_number = {blog.article_number}
                    image={blog.Image_Url}
                    title={blog.Title}
                    date={blog.Date}
                    summary={blog.Summary}
                    redirectLink={blog.URL}
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      ) : (
        <NoContent />
      )}
    </Container>
    </>
  )
}

export default Blogs