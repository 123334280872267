import React, {useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AuthCard from './../Components/AuthCard';
import './../App.css';

function Login() {

  // Test Purpose remove username 
  useEffect(() => {
    localStorage.removeItem('username')
  },[])


  return (
    <>
      <Container fluid className="d-flex flex-column">
        <Row className="flex-grow-1">
          <Col md={6} className="d-flex justify-content-center align-items-center bg-light">
            <AuthCard />
          </Col>

          <Col md={6} className="d-none d-md-flex justify-content-center align-items-center login-image">
            <div className="text-center text-white">
              <h1 className="mb-4">Welcome!</h1>
              <p className="mb-5 text-white">Stay aware, stay ahead, stay compliant.</p>
              {/* <img src="./avatar-1.png" alt="Dashboard Screenshot" className="img-fluid" style={{height:"400px"}} /> */}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
