import React, { useState, useEffect } from 'react';
import UserProfileBlogs from './../Components/UserProfileBlogs';
import UserProfileCard from './../Components/UserProfileCard';
import UserProfileCover from './../Components/UserProfileCover';
import UserProfileTab from './../Components/UserProfileTab';
import ProfileForm from './../Components/ProfileForm';
import UserProfileForm from './../Components/UserProfileForm';
import UserProfileCardNew from '../Components/DoctorProfileCard/UserProfileCardNew';
import UserProfileConferences from '../Components/UserProfileConferences';
import { createAvatarCover, getBlogs, createBanner, getUSerProfileImages, getAvatarProfileImage, getAvatarBannerImages } from '../api';
import { redirect } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Form, InputGroup } from 'react-bootstrap';
import { checkUserProfileCreated, fecthAllColleges, fileUplaod, UpdateUserAccount } from './../api';

// import { Card, Form, Button, InputGroup, Row, Col } from 'react-bootstrap';
// if details submitted check in local storage 
// if yes? -> call avatar creation, cover creation, profile details 
// if skip? --> show default detials 
// want to update profile? yes -> show form -> profile, cover, -> satisfied ? 2 options -> final submit the profile. 
// check for the blogs? -> if blog is present in the loacl storage? no then call the api and save the blogs in loacl storage. 



const tweets = [
    {
        username: 'Benjamin Hoppe',
        text: 'How can we use design to make Twitter more engaging and less about the metrics?',
    },
    {
        username: 'Benjamin Hoppe',
        text: 'Yeah I can for sure pop by - will call you early next week.',
    },
    {
        username: 'Benjamin Hoppe',
        text: 'Why do I drink so much coffee?',
    }
];

const blogs = [
    {
        image: 'https://via.placeholder.com/150',
        title: 'Blog Post 1',
        summary: 'This is a summary of blog post 1',
    },
    {
        image: 'https://via.placeholder.com/150',
        title: 'Blog Post 2',
        summary: 'This is a summary of blog post 2',
    },
    {
        image: 'https://via.placeholder.com/150',
        title: 'Blog Post 3',
        summary: 'This is a summary of blog post 3',
    },
];


function ProfilePage() {

    // states 
    const [username, setUserName] = useState("");
    const [name, setName] = useState("");
    const [degree, setDegree] = useState("");
    const [contact, setContact] = useState("");
    const [shareProfileLink, setShareProfileLink] = useState("");
    const [coverImageUrl, setCoverImageUrl] = useState("");
    const [userProfileCardPic, setUserProfileCardPic] = useState("")
    const [loading, setLoading] = useState(false);
    const [blogss, setBlogss] = useState([]);
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('media');
    const [isAvatar, setIsAvatar] = useState(false);
    const [popUpForm, setPopUpForm] = useState(false);
    const [allowCreateAvatar, setAllowCreateAvatar] = useState(false)
    const [allowProfile, setAllowProfile] = useState(false)


    // *******************************************************
    // if images present then fetch - original profile images
    // *******************************************************
    const fetchOriginalProfileImage = async (userProfileImageName) => {
        const result = await getUSerProfileImages(userProfileImageName);
        if (result) {

            return result

        }
    }


    // **********************************
    // if images present fetch the avatar
    // **********************************
    const fetchAvatarProfile = async (savedAsFileName) => {

        const result = await getAvatarProfileImage(savedAsFileName)
        if (!result) {
            return false

        }
        else if (result === "noUrl") {
            setUserProfileCardPic(`${process.env.PUBLIC_URL}/profileimages/avatar-2.png`)
            return "noUrl"
        }
        else {
            // set the original profile image
            return result
        }
    }


    // **********************************
    // if banner present fetch banner image
    // **********************************
    const fetchBannerImage = async (userProfileImageName) => {
        const result = await getAvatarBannerImages(userProfileImageName)
        if (!result) {
            return false

        }
        else if (result === "noUrl") {
            return "noUrl"
        }
        else {
            // set the original profile image
            // userProfile.bannerProfileS3Url = result
            // localStorage.setItem("userprofile", JSON.stringify(userProfile))
            // setCoverImageUrl(result)
            return result;
        }
    }

    // ***************************
    //  fecth blogs - in usestates
    // ***************************
    const fetchBlogs = async () => {
        const result = await getBlogs();

        if (!result) {
            console.log("API ERROR")

        }
        else {

            const top3 = result.slice(0, 3)
            setBlogss(top3);
        }
    }

    // all the conditions will check here 
    // condition 1 - no profile created 
    // condition 2 - profile created
    // condition 3 - blogs 
    useEffect(() => {
        //********************************************* */
        // step - 1
        // condition 3 - check blogs in local storage 
        // fetch blogs fucntion - api 
        //********************************************* */
        const local_blogs = JSON.parse(localStorage.getItem("blogs"))
        if (!local_blogs) {
            fetchBlogs();
        }
        else {
            const top3 = local_blogs.slice(0, 3)
            setBlogss(top3)
        }


        // *****************************************
        // step 2
        // check userprofile 
        // condition 1. no images - improvements
        // condition 2. yes images - this will call getoriginal, avatar, banner
        // *****************************************
        const userprofile = JSON.parse(localStorage.getItem("userprofile"))


        if (userprofile.username === "Default User") {
            setUserName(userprofile.username);
            setContact(userprofile.email);
            setDegree(userprofile.degree);
            setShareProfileLink(userprofile.profileLink);
            setCoverImageUrl(`${process.env.PUBLIC_URL}/profileimages/cover-new.png`);
            setUserProfileCardPic(`${process.env.PUBLIC_URL}/profileimages/faceless.png`);
            // call the blogs api here 
            // check if blogs local storage ? use local storage : call the api 
        }
        // all depends upaon the local storage info. 
        // 
        else {

            setUserName(userprofile._sourceObject.username)
            setName(userprofile._sourceObject.name)
            setContact(userprofile._sourceObject.login ? userprofile._sourceObject.login : "No Data")
            setDegree(userprofile._sourceObject.qualification === "" ? "No Data" : userprofile._sourceObject.qualification)
            // setShareProfileLink(userprofile._sourceObject.username === "" ? "No Data" : `http://localhost:3000/profile/${userprofile._sourceObject.username}`)
            // setShareProfileLink(userprofile._sourceObject.username === "" ? "No Data" : `http://13.201.26.62/profile/${userprofile._sourceObject.username}`)
            setShareProfileLink(userprofile._sourceObject.username === "" ? "No Data" : `https://www.billionheart.com/profile/${userprofile._sourceObject.username}`)

            // **************************************************
            // check if avatar created.
            // **************************************************
            const checkIfAvatarCreated = async () => {
                const userdata = JSON.parse(localStorage.getItem("userprofile"));
                try {
                    // Fetch the original profile image asynchronously
                    const result = await fetchAvatarProfile(userdata._sourceObject.images[0].saved_as);

                    // Check if a valid result was returned from the API
                    if (result) {
                        return true // Set the profile picture to the one returned from API
                    }
                } catch (error) {
                    console.error("maybe avatar not created", error)
                }
            }
            // **************************************************
            // this fucntion is used to handle images if present 
            // **************************************************
            const handleProfileImage = async () => {

                //*****************************
                // setting the profile images
                //*****************************
                try {
                    // Fetch the original profile image asynchronously
                    const result = await fetchOriginalProfileImage(userProfileImageName);


                    // Check if a valid result was returned from the API
                    if (result) {
                        setUserProfileCardPic(result); // Set the profile picture to the one returned from API
                    } else {
                        // If no valid result, set to default image
                        setUserProfileCardPic(`${process.env.PUBLIC_URL}/profileimages/avatar-2.png`);
                    }
                } catch (error) {
                    console.error("Error fetching the image:", error);
                    // Handle the error (e.g., set default image)
                    setUserProfileCardPic(`${process.env.PUBLIC_URL}/profileimages/avatar-2.png`);
                }

                //**************************
                // setting the banner image
                //**************************
                try {
                    // Fetch the original profile image asynchronously
                    const result = await fetchBannerImage(userProfileImageName);

                    // Check if a valid result was returned from the API
                    if (result) {
                        setCoverImageUrl(result); // Set the profile picture to the one returned from API
                        setAllowCreateAvatar(false);
                        setAllowProfile(true);
                    }
                    else if (result === "noUrl") {
                        setCoverImageUrl(`${process.env.PUBLIC_URL}/profileimages/defaultCover.png`);
                        setAllowCreateAvatar(true);
                        setAllowProfile(false);

                    }
                    else {
                        // set the allowavatar - false 
                        setCoverImageUrl(`${process.env.PUBLIC_URL}/profileimages/defaultCover.png`);
                    }
                } catch (error) {
                    console.error("Error fetching the image:", error);
                    // Handle the error (e.g., set default image)
                    setCoverImageUrl(`${process.env.PUBLIC_URL}/profileimages/defaultCover.png`);
                }


            }


            // **********************************************
            // main logic - check if user images are present? 
            // **********************************************
            const userProfileImageName = userprofile._sourceObject.images.length > 0 ? userprofile._sourceObject.images[0].saved_as : ""




            if (userProfileImageName !== "") {
                //*****************************************
                // images are present - profile is created
                // inside call the fucntion 
             
                //*****************************************
                const result = checkIfAvatarCreated()
                if (result) {
                    setAllowCreateAvatar(false);
                    setAllowProfile(true);
                }
                // Inside this we are calling 
                // get the original images 
                // get the avatar images 
                // get the banner images
                handleProfileImage();
            }
            //********************
            // images are not present 
            //********************
            else {
                // set the state 
                // allow avatar create true
                // allow profile create false
                setAllowCreateAvatar(true);
                setAllowProfile(false);
                setUserProfileCardPic(`${process.env.PUBLIC_URL}/profileimages/defaultProfile.png`)
                setCoverImageUrl(`${process.env.PUBLIC_URL}/profileimages/defaultCover.png`)

            }

        }
    }, [])


    function showPopUp() {
        setPopUpForm(true)
    }

    // fetch the avatar and cover image
    const fetchAvatarCoverImages = async () => {
        const userprofile = localStorage.getItem("userprofile")
        const response = JSON.parse(userprofile);
        const username = response.username
        const degree = response.degree
        const college = response.college
        const gender = response.gender
        const age = response.age
        const file_name = response.profileimagename
        try {
            const avatarResult = await createAvatarCover(username, degree, college, gender, age, file_name);
            const bannerResult = await createBanner(degree, file_name);
            
            if (avatarResult && bannerResult) {
                // If both functions return valid URLs, set the new URLs
                setCoverImageUrl(bannerResult.bannerUrl);
                setUserProfileCardPic(avatarResult.avatar_url);
            } else {
                // If either avatarResult or bannerResult is false, set default URLs
                console.log("Error in avatar or banner URL API. Setting default images.");
                setUserProfileCardPic(`${process.env.PUBLIC_URL}/profileimages/faceless.png`)
                setCoverImageUrl(`${process.env.PUBLIC_URL}/profileimages/cover-new.png`)
            }
        } catch (error) {
            console.log("avatar API error", error);
            setUserProfileCardPic(`${process.env.PUBLIC_URL}/profileimages/faceless.png`)
            setCoverImageUrl(`${process.env.PUBLIC_URL}/profileimages/cover-new.png`)
        } finally {
            setLoading(false);
        }
        

    }


    //********************************
    // Toggle Profile image
    //********************************
    const toggleProfileImage = async () => {

        const userdata = JSON.parse(localStorage.getItem('userprofile'));

        if (isAvatar) {

            const result = await fetchOriginalProfileImage(userdata._sourceObject.images[0].saved_as);

            // Check if a valid result was returned from the API
            if (result) {
                setUserProfileCardPic(result); // Set the profile picture to the one returned from API
            }

        } else {
            // Set the profile to the avatar image
            const result = await fetchAvatarProfile(userdata._sourceObject.images[0].saved_as)
            if (result) {
                setUserProfileCardPic(result); // Set the profile picture to the one returned from API
            }

        }

        // Toggle the state
        setIsAvatar(!isAvatar);
    }

    // const redirect to the profile page
    const redirectToProfilePage = async () => {
        navigate('/profileform')
    }
    // ****************************
    // IMP LOGIC
    // here we are setting the
    // handle form submit code here
    //*****************************
    const handleUserFormSubmit = async (data) => {
        // upload the file to s3 
        // if success ? 
        if (data) {
            // Step 1: first upload file to s3 and get the filename
            const LocalfileName = data.image.name;

            // Step 2: Convert file reading to a promise to use with async/await
            const readFileAsArrayBuffer = (file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => resolve(reader.result); // Resolves with the ArrayBuffer
                    reader.onerror = (error) => reject(error);    // Rejects with an error
                    reader.readAsArrayBuffer(file);
                });
            };

            // Step 3: Wait for the file to be read as an ArrayBuffer
            const arrayBuffer = await readFileAsArrayBuffer(data.image);

            // Step 4: Call the uploadFile function and await its result

            const fileUplaodResult = await fileUplaod(LocalfileName, arrayBuffer);

            if (fileUplaodResult) {


                // get the local storage  
                const userprofile = JSON.parse(localStorage.getItem("userprofile"))
                if (userprofile) {
                    // creating the object for put request 
                    const userData = {
                        "address": userprofile._sourceObject.address,
                        "date_of_birth": data.dob,
                        "email": userprofile._sourceObject.email.length > 0 ? userprofile._sourceObject.email : [],
                        "gender": data ? data.gender : "",
                        "hospital_name": userprofile._sourceObject.hospital_name ? userprofile._sourceObject.hospital_name : "",
                        "images": [{
                            "original_filename": data ? data.image.name : "",
                            "saved_as": fileUplaodResult ? fileUplaodResult.saved_as : "",
                            "title": "user_image"
                        }],
                        "institute_name": data ? data.college : "",
                        "name": userprofile._sourceObject.name,
                        "phone": userprofile._sourceObject.phone,
                        "qualification": data ? data.degree : "",
                        "record_status": "active",
                        "record_type": "doctor",
                        "specialization": [],
                        "year_of_experience": "NA"
                    }
                    const accountUpdated = await UpdateUserAccount(userData, userprofile._sourceObject.username)

                    if (accountUpdated) {
                        // ********************
                        // avatar api calling 
                        // ********************
                        // loding effect 
                        setPopUpForm(false)
                        setLoading(true)
                        const avatarProfile = await createAvatarCover(userprofile._sourceObject.name, data.degree, data.college, data.gender, data.age, fileUplaodResult.saved_as);
                        // here api will return if url get 
                        // if false - stop loading. 
                        // allow create avatar true 
                        // profile avatar false
                        // stop loading and show the msg new state here 

                        // if true then 
                        // stop loading 
                        // set allow avatar false 
                        // set allow profile ture 
                        // save local storage - new 
                        // call the post api (optional)
                        // load the component
                        if (!avatarProfile) {
                            // check if else condition is required
                            console.log("failed avatar")
                            console.log("set the allow avatar true")
                            console.log("set the allow profile false")
                            setUserProfileCardPic(`${process.env.PUBLIC_URL}/profileimages/faceless.png`)
                        }
                        else {
                            // set the avatar profile iamge 
                            // set the allow state here 
                            console.log("success avatar")
                            console.log("set the allow avatar false")
                            console.log("set the allow profile true")
                            console.log("post the api , save the local storage.")
                            console.log("reload the component here")
                            console.log("set the loading false")
                            console.log("set the avatar profile")
                            setUserProfileCardPic(avatarProfile.avatar_url);
                        }
                        // ********************
                        // banner api calling 
                        // ********************
                        const bannerImage = await createBanner(data.degree, fileUplaodResult.saved_as)
                        if (!bannerImage) {
                            setCoverImageUrl(`${process.env.PUBLIC_URL}/profileimages/cover-new.png`)
                        }
                        else {
                            console.log("success banner")
                            console.log("set the allow avatar false")
                            console.log("set the allow profile true")
                            console.log("post the api , save the local storage.")
                            console.log("reload the component here")
                            console.log("set the loading false")
                            console.log("set the avatar profile")
                            setAllowCreateAvatar(false);
                            setAllowProfile(true);
                            checkUserProfileCreated();
                            setLoading(false);
                            setCoverImageUrl(bannerImage.bannerUrl);
                        }
                    }
                    else {
                        console.log("PUT API Update the account api error")
                    }


                }
                else {
                    console.log("Local storage userProfileData Not avilable!")
                }
            }
            else {
                console.log("File Uplaod api error!")
            }
        }
        else {
            console.log(" Form data not submitted error")
        }

    }

    return (
        <>
            <div className="container mt-0">

                <div className="row">
                    <div className="col-md-3">
                        {/* here we have to pass the defualt values  */}

                        <UserProfileCard name={name} loading={loading} allowCreateAvatar={allowCreateAvatar} allowProfile={allowProfile} userProfileCardPic={userProfileCardPic} username={username} degree={degree} contact={contact} shareProfileLink={shareProfileLink} fetchAvatarCoverImages={fetchAvatarCoverImages} redirectToProfilePage={redirectToProfilePage} toggleProfileImage={toggleProfileImage} isAvatar={isAvatar} showPopUp={showPopUp} />
                    </div>
                    <div className="col-md-8">

                        <UserProfileCover coverImageUrl={coverImageUrl} />
                        <UserProfileTab activeTab={activeTab} onTabChange={setActiveTab} />

                        {/* Conditionally render content based on active tab */}
                        {activeTab === 'tweets-replies' && tweets.map((tweet, index) => (
                            <UserProfileConferences tweet={tweet} key={index} />
                        ))}

                        {activeTab === 'tweets' && tweets.map((tweet, index) => (
                            <UserProfileConferences tweet={tweet} key={index} />
                        ))}

                        {activeTab === 'media' && blogss.map((blog, index) => (
                            <UserProfileBlogs blog={blog} key={index} />
                        ))}

                    </div>

                    {/* Popup Form (Bootstrap Classes) */}
                    {popUpForm && (
                        <div className="modal show d-block" tabIndex="-1">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ maxHeight: '70vh' }}>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Profile Form</h5>
                                        <button type="button" className="btn-close" onClick={() => setPopUpForm(false)}></button>
                                    </div>
                                    <div className="modal-body">
                                        {/* <ProfileForm /> */}
                                        <UserProfileForm handleUserFormSubmit={handleUserFormSubmit} />
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={() => setPopUpForm(false)}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </>
    )
}

export default ProfilePage