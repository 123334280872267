import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Alert } from 'react-bootstrap';
import '../App.css';
import BioBlogs from '../Components/BioBlogs';
import ShareUserProfile from '../Components/ShareUserProfile';
import ProfileBanner from '../Components/ProfileBanner';
import UserProfileBlogs from '../Components/UserProfileBlogs';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import AlertMessage from '../Components/AlertMessage.jsx';
import { shareProfileUrl, getAvatarBannerImages, getUSerProfileImages, getBlogs } from '../api.js';



function ShareProfileUrl2() {
    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [nodata, setNodata] = useState(false)
    const [banner, setBanner] = useState("")
    const [profile, setProfile] = useState("")
    const [bioDetails, setBiDetails] = useState("")
    const { username, article_number } = useParams();
    const [blogss, setBlogss] = useState([]);
    const [articel, setArticel] = useState({});
    const [isArticel, setIsArticel] = useState(false)
    // *****************
    //  fecth blogs
    // *****************
    const fetchBlogs = async () => {
        const result = await getBlogs();

        if (!result) {
            console.log("API ERROR")
        }
        else {
            const article = result.find((item) => item.article_number === parseInt(article_number));

            if (article) {
                setArticel(article)
                setIsArticel(true);
            }
            else {
                setIsArticel(false)
            }
        }
    }


    // API Call
    useEffect(() => {

        if (article_number) {
            const result = fetchBlogs();
        }




        // Define the config object for Axios
        const fetchUserProfile = async () => {
            const result = await shareProfileUrl(username)
            if (!result) {
                setError("shareProfileUrl API Faield ")
                setLoading(false)
            }
            else if (Array.isArray(result) && result.length > 0) {

                setProfileData(result[0]); // here we are getting the profile data 
                setLoading(false)

                // check if user profile image is present                
                if (Array.isArray(result[0].images) && result[0].images.length > 0) {

                    const profile_url_result = await getUSerProfileImages(result[0].images[0].saved_as)
                    if (!profile_url_result) {
                        setProfile(`${process.env.PUBLIC_URL}/profileimages/defaultProfile.png`)
                    }
                    else if (profile_url_result === "noUrl") {
                        setError("No user Present")
                        setProfile(`${process.env.PUBLIC_URL}/profileimages/defaultProfile.png`)

                    }
                    else {
                        setProfile(profile_url_result) // here we are getting our profile url
                    }


                    //banner api
                    const url_result = await getAvatarBannerImages(result[0].images[0].saved_as)
                    if (!url_result) {
                        setBanner(`${process.env.PUBLIC_URL}/profileimages/cover-new.png`)
                    }
                    else if (url_result === "noUrl") {
                        setBanner(`${process.env.PUBLIC_URL}/profileimages/cover-new.png`)
                    }
                    else {
                        setBanner(url_result)
                    }

                }
                else {
                    setBanner(`${process.env.PUBLIC_URL}/profileimages/cover-new.png`)
                    setProfile(`${process.env.PUBLIC_URL}/profileimages/defaultProfile.png`)
                }

            }
            else if (result === "no user") {
                setNodata(true);
                setLoading(false)
            }
            else {
                setError("shareProfileUrl API : Unexpected Error")
                setLoading(false)
            }

        }
        fetchUserProfile();

    }, [username]);

    // calling api 
    // loading 
    if (loading) {
        return <div>Loading...</div>;
    }

    // check error
    if (error) {
        return (
            <AlertMessage
                variant="danger"
                heading="Error"
                message={`An error occurred: ${error.message}`}
                onClose={() => setError(null)}
            />
        );
    }

    // check data
    if (nodata) {
        return (
            <AlertMessage
                variant="warning"
                heading="No data Found"
                message={`No record found for the user "${username}".`}
                onClose={() => setNodata(null)}
            />
        );
    }
    // checking if getting the profile images
    // checking if getting bannner 
    //checking if getting profile data.
 
    return (
        <Container className="mt-4">
            {/* Banner and Profile Image */}
            <Row className='d-flex flex-column align-items-center'>
                <Col md={8}>
                    <Card className="shadow-sm rounded">
                        {/* Profile Banner */}
                        <ProfileBanner banner={banner} profileImage={profile} />

                        {/* User Details */}
                        <Card.Body className="text-center mt-5">
                            <ShareUserProfile profileData={profileData} />
                        </Card.Body>
                    </Card>
                </Col>



                {/* Bio Section */}
                {/* <Col md={8} className='mb-4'>
                    <BioBlogs bioDetails={profileData} />
                </Col> */}

                {article_number ?
                    <Col md={8} className='mb-4 mt-4'>
                        <UserProfileBlogs blog={articel} />
                    </Col>
                    :
                   ""
                }


            </Row>
        </Container>
    )
}

export default ShareProfileUrl2