import React from 'react'
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


function UserProfileConferences({tweet}) {
    const navigate = useNavigate();

    const handleRedirect = () => {
      navigate('/blogs'); // Replace '/destination-page' with the route you want to redirect to
    };
  
  return (
    <>
        <Card className="mb-3">
            <Card.Body>
              <div className="d-flex align-items-center">
                    <img src={`${process.env.PUBLIC_URL}/profileimages/portofolio.png`} alt="User" className="rounded-circle mr-3" style={{height:"50px", width:"50px"}} />
                    <div className='mx-4'>
                        {/* <h6>{tweet.username}</h6>
                        <p>{tweet.text}</p> */}
                        <p >Coming Soon</p>
                    </div>
                    {/* <div>
                    <button className='btn btn-primary' onClick={handleRedirect}>check</button>
                    </div> */}
                </div>
            </Card.Body>
        </Card>
    </>
  )
}

export default UserProfileConferences