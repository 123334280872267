import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { FaPhone, FaGraduationCap, FaLink, FaCopy } from 'react-icons/fa';
import { checkUserProfileCreated } from '../api';
import { useNavigate } from 'react-router-dom';


function UserProfileCard({name,  loading, allowCreateAvatar, allowProfile, userProfileCardPic, username, degree, contact, shareProfileLink, fetchAvatarCoverImages, redirectToProfilePage, toggleProfileImage, isAvatar, showPopUp }) {
  const [copySuccess, setCopySuccess] = useState(''); // State to show copy success message
  const navigate = useNavigate();
  // Function to handle copy to clipboard
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(shareProfileLink).then(
      () => {
        setCopySuccess('Link copied!'); // Update state with success message
        setTimeout(() => setCopySuccess(''), 2000); // Clear success message after 2 seconds
      },
      (err) => {
        console.error('Failed to copy: ', err); // Handle error if copy fails
      }
    );
  };

  return (
    <>
      <div
        className="user-profile-card p-4 rounded shadow border border-info"
        style={{
          // background: 'linear-gradient(135deg, #F8F9FA 60%, #FFFFFF 100%)',
          background: 'linear-gradient(135deg, #F8F9FA 60%, #FFFFFF 100%)',
          borderRadius: '15px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          textAlign: 'center',
          position: 'relative',
          overflow: 'hidden',
        }}
      >

        {/* profile Body - details */}
        {username !== "Default User" ?
          //***********************
          // submited detials card
          //***********************
          <div>
            {/*  profile Image */}
            <div className='d-flex justify-content-center mb-3'>
              <img
                src={userProfileCardPic} // here dynamic file has be to sent
                alt="User"
                className="rounded-circle"
                style={{
                  height: '150px',
                  width: '150px',
                  border: '2px solid #285AAF',
                  padding: '2px'
                }}
              />
            </div>



            <div >
              <h3 style={{ color: '#285AAF', fontWeight: 'bold', fontSize: '1.5rem' }}>{name}</h3>
            </div>
            <div className='p-2 my-2'>
              {/* contact */}
              <div className='d-flex justify-content-start'>
                <p style={{ color: '#6c757d' }}>
                  <FaPhone style={{ marginRight: '10px', color: '#285AAF' }} />
                </p>
                <p style={{ color: '#6c757d', fontSize: '0.9rem' }}>{contact}</p>
              </div>

              {/* degree */}
              <div className='d-flex justify-content-start'>
                <p style={{ color: '#6c757d' }}>
                  <FaGraduationCap style={{ marginRight: '10px', color: '#285AAF' }} />
                </p>
                <p style={{ color: '#6c757d', fontSize: '0.9rem' }}> {degree}</p>
              </div>

              {/* Share link */}
              {/* Share link */}
              <div className='d-flex align-items-center'>
                <p style={{ color: '#6c757d' }}>
                  <FaLink style={{ marginRight: '10px', color: '#285AAF' }} />
                </p>
                <p style={{ color: '#6c757d', marginRight: '10px', wordBreak: 'break-all', flexGrow: 1 }}>
                  <a href={shareProfileLink} style={{ color: '#285AAF', textDecoration: 'none', fontSize: '0.9rem' }}>{shareProfileLink}</a>
                </p>
                {/* Copy to clipboard button */}
                <button
                  onClick={handleCopyToClipboard}
                  style={{
                    background: '', // No background color
                    border: 'none', // No border
                    color: '#285AAF', // Icon color
                    cursor: 'pointer', // Pointer cursor on hover
                    padding: '0', // No padding
                    margin: '0 0px', // Small margin
                    display: 'flex', // Align as flex to center icon
                    alignItems: 'center', // Center icon vertically
                  }}
                >
                  <FaCopy />
                </button>
              </div>


              {/* Success message */}
              {copySuccess && <p style={{ color: 'green', fontSize: '0.8rem' }}>{copySuccess}</p>}
            </div>
            <div className='d-flex gap-2 justify-content-center'>
              <div>
                {loading ? (
                  <p>
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                  </div>
                </p>
                ) : (
                  allowCreateAvatar && (
                    <Button
                      variant="primary"
                      className="w-100 my-2 btn-sm"
                      style={{
                        backgroundColor: '#285AAF',
                        borderColor: '#285AAF',
                        fontWeight: 'bold',
                        borderRadius: '30px',
                        boxShadow: '0 2px 4px rgba(40, 90, 175, 0.4)'
                      }}
                      onClick={showPopUp}
                    >
                      Create avatar
                    </Button>
                  )
                )}
              </div>



              {allowProfile ? <Button
                variant="primary"
                className="w-100 my-2 btn-sm"
                style={{
                  backgroundColor: '#285AAF',
                  borderColor: '#285AAF',
                  fontWeight: 'bold',
                  borderRadius: '30px',
                  boxShadow: '0 2px 4px rgba(40, 90, 175, 0.4)'
                }}
                onClick={toggleProfileImage}>  {isAvatar ? "Profile Image" : "Avatar Image"}
              </Button>
                : ""}


            </div>

          </div>
          :
          //*******************
          // Defualt user card 
          //*******************
          <div>
            {/*  profile Image */}
            
            <div className='d-flex justify-content-center mb-3'>
              <img
                src={userProfileCardPic}
                alt="User"
                className="rounded-circle"
                style={{
                  height: '150px',
                  width: '150px',
                  border: '5px solid #285AAF',
                  padding: '5px'
                }}
              />
            </div>
            {/* Profile body */}
            <div >
              <h3 style={{ color: '#285AAF', fontWeight: 'bold' }}>{username}</h3>
            </div>
            <Button
              variant="primary"
              className="w-100 my-2"
              style={{
                backgroundColor: '#285AAF',
                borderColor: '#285AAF',
                fontWeight: 'bold',
                borderRadius: '30px',
                boxShadow: '0 2px 4px rgba(40, 90, 175, 0.4)'
              }}
              onClick={redirectToProfilePage}
            >
              Create Profile
            </Button>
          </div>}
      </div>
    </>
  )
}

export default UserProfileCard;