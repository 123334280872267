
import axios from 'axios';

export const checkUserProfileCreated = async () => {
  const API_URL = process.env.REACT_APP_GET_PROFILE
  const userData = JSON.parse(localStorage.getItem("userData"))
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: API_URL,
    headers: {
      'Authorization': userData ? userData.idToken : "",
      'x-op': 'get-profile',
      'x-record-type': 'doctor',
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios.request(config)
    if (response.status === 200) {
      if (response.data.hits.total.value > 0) {
        // Function to calculate age from DOB
        // redirect to profile page here
        // here we have this user as registered so we will calcualte the age here 
        // and the detials which we are getting we are going to save in the local storage 
        // else we return false here
        const calculateAge = (dob) => {
          const birthDate = new Date(dob);
          const today = new Date();
          let age = today.getFullYear() - birthDate.getFullYear();
          const monthDifference = today.getMonth() - birthDate.getMonth();

          // Adjust age if the birthdate has not yet occurred this year
          if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
          }
          return age;
        };
        // Here submit form detials 
        // already present userdetails
        const defaultProfile = {
          username: response.data.hits.hits[0]._source.name,
          email: response.data.hits.hits[0]._source.email.lenght > 0 ? response.data.hits.hits[0]._source.email[0].value : "no email",
          degree: response.data.hits.hits[0]._source.qualification,
          college: response.data.hits.hits[0]._source.institute_name,
          gender: response.data.hits.hits[0]._source.gender,
          age: calculateAge(response.data.hits.hits[0]._source.date_of_birth),
          profileimagename: response.data.hits.hits[0]._source.images.lenght > 0 ? response.data.hits.hits[0]._source.images[0].saved_as : `${process.env.PUBLIC_URL}/profileimages/logo.jpeg`,
          profileLink: `http://localhost:3000/profile/${response.data.hits.hits[0]._source.username}`,
          _sourceObject: response.data.hits.hits[0]._source
        }
        const other = { "response": response.data.hits.hits[0]._source }
        localStorage.setItem("userprofile", JSON.stringify(defaultProfile));
        localStorage.setItem("all", JSON.stringify(other));
        return true
      }
      else {
        return false
      }
    }
  } catch (error) {
    throw error; // Rethrow the error if you want it to be caught elsewhere
  }
}


//get all college list
export const fecthAllColleges = async () => {
  const userData = JSON.parse(localStorage.getItem("userData"))
  const API_URL = process.env.REACT_APP_GET_COLLEGES
  // const REACT_APP_GET_COLLEGES_API_KEY = process.env.REACT_APP_GET_COLLEGES_API_KEY
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: API_URL,
    headers: {
      // 'x-api-key': 'QhEXIJanqrhXGk8h32FQ49UiMljjTdg8KSSeGmfmgyzIqJ26wF5BdqlE1pm8ndERFAEz0X4tu92W853xVCUaXtZeZ4lFhATGWvDizCCcAqbirQ0qEqn5sYooHHwh1gvc3ypxyfVPmvLyjHzH3854fQvAZeAnmbn0XLsjKkDav037A4Wab437bM6pqeuGz7drj0JiXsOtsmy2Wpatph79SDTeT4TTx72ZCPP1Pr4v4QvEpr4d3IDPtvccdIbUg2vZmS0jkwbqRwtITeC3u65iUKzZp3UKd2CdO3fIlbg4yX8RER0oal1rtAYvf3M9LV2s'
      'Authorization': userData ? userData.idToken : ""
    }
  };


  try {
    const response = await axios.request(config)
    if (response.status === 200) {


      return response.data.Data
    }

  } catch (error) {
    console.error("Error checking user account:", error);
    throw error; // Rethrow the error if you want it to be caught elsewhere
  }

}


// upload file to cloud
export const fileUplaod = async (filename, arrayBuffer) => {
  const API_URL = process.env.REACT_APP_FILEUPLAOD


  const userData = JSON.parse(localStorage.getItem("userData"))
  // Set up the axios config
  const config = {
    method: 'put',
    maxBodyLength: Infinity,
    url: `${API_URL}${filename}`,
    headers: {
      'Authorization': userData ? userData.idToken : "",
      'x-op': 'upload-doctor-profile',
      'x-record-type': 'profile~doctor',
      'Content-Type': 'application/octet-stream'
    },
    data: arrayBuffer // Use the ArrayBuffer data
  };

  // Make the API request
  try {
    const response = await axios.request(config)
    if (response.status === 200) {


      return { "original_filename": response.data.OriginalFileName, "saved_as": response.data.SavedAs, "title": "user_image" } // get this file name and then set the file name variable
    }
    else {
      return false
    }

  } catch (error) {
    console.log("Error", error)
  }
}


// create account post api 
export const createUserAccount = async (fullname, originalfileName, savedAsfileName, college, degree, dob, gender) => {
  const API_URL = process.env.REACT_APP_CREATEACCOUNT
  const userData = JSON.parse(localStorage.getItem("userData"))
  let data = JSON.stringify({
    "name": fullname,
    "gender": gender,
    "date_of_birth": dob,
    "address": "",
    "hospital_name": "",
    "location": {},
    "phone": [],
    "email": [],
    "images": originalfileName === null ? [] : [{ "original_filename": originalfileName, "saved_as": savedAsfileName, "title": "user_image" }],
    "institute_name": college,
    "qualification": degree,
    "record_status": "active",
    "specialization": [],
    "year_of_experience": ""
  });


  // config 
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: API_URL,
    headers: {
      'Authorization': userData ? userData.idToken : "",
      'x-op': 'create-user',
      'x-record-type': 'doctor',
      'Content-Type': 'application/json'
    },
    data: data
  };

  // call the axois 
  try {
    const response = await axios.request(config)
    if (response.status === 200) {
      return true;
    }
  } catch (error) {

    return false
    // throw to catch the error
  }
}


export const UpdateUserAccount = async (dataa, username) => {
  const API_URL = process.env.REACT_APP_UPDATEACCOUNT
  let data = JSON.stringify(dataa);
  const userData = JSON.parse(localStorage.getItem("userData"))
  let config = {
    method: 'put',
    maxBodyLength: Infinity,
    url: `${API_URL}/${username}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': userData ? userData.idToken : "",
      'x-op': 'modify-profile',
      'x-record-type': 'doctor'
    },
    data: data
  };

  // call the axois 
  try {
    const response = await axios.request(config)
    if (response.status === 200) {
      
      return true;
    }
  } catch (error) {
    return false
    // throw to catch the error
  }
}



// export const createAvatarCover = async (username, degree, college, gender, age, file_name) => {
//   alert("calling avatar api....");
//   console.log(username, degree, college, gender, age, file_name);

//   const data = JSON.stringify({
//     "name": username,
//     "college_name": college,
//     "degree": degree,
//     "gender": gender,
//     "age": age.toString(),
//     "country": "India",
//     "file_name": file_name
//   });

//   const config = {
//     method: 'post',
//     maxBodyLength: Infinity,
//     url: 'https://ue58z1vdr4.execute-api.ap-south-1.amazonaws.com/api_dev/create_avatar',
//     headers: {
//       'Authorization': authorizationtoken, // Replace with actual token
//       'x-op': 'create-avatar',
//       'Content-Type': 'application/json'
//     },
//     data: data
//   };

//   try {
//     const response = await axios.request(config);

//     if (response.status === 200 && response.data.Code === "Success") {
//       console.log("Avatar created successfully:", response.data);

//       // Start polling for avatar and banner
//       const avatarUrlPromise = pollAvatarAPI(file_name);
//       // const bannerUrlPromise = pollBannerAPI(file_name);

//       const [avatarUrl, bannerUrl] = await Promise.all([avatarUrlPromise, bannerUrlPromise]);

//       if (!avatarUrl || !bannerUrl) {
//         return false;
//       }

//       return { "avatar_url": avatarUrl, "banner_url": bannerUrl };
//     } else {
//       console.log("Failed to create avatar. Response data:", response.data);
//     }
//   } catch (error) {
//     console.log("Error occurred while creating avatar:", error);
//   }

//   return false;
// };

// calling this api 
export const createAvatarCover = async (username, degree, college, gender, age, file_name) => {
  const API_URL = process.env.REACT_APP_CREATEAVATAR_POST
  const userData = JSON.parse(localStorage.getItem("userData"))
  const data = JSON.stringify({
    "name": username,
    "college_name": college,
    "degree": degree,
    "gender": gender,
    "age": age.toString(),
    "country": "India",
    "file_name": file_name
  });

  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: API_URL,
    headers: {
      'Authorization': userData ? userData.idToken : "",
      'x-op': 'create-avatar',
      'Content-Type': 'application/json'
    },
    data: data
  };

  try {
    const response = await axios.request(config);

    if (response.status === 200 && response.data.Code === "Success") {

      const avatarUrlPromise = pollAvatarAPI(file_name);
      // const bannerUrlPromise = pollBannerAPI(file_name);

      const [avatarUrl] = await Promise.all([avatarUrlPromise]);

      if (!avatarUrl) {
        console.log("Avatar creation failed or not found after retries.");
        return false;
      }

      return { "avatar_url": avatarUrl };
    } else {
      console.log("create avatar post api faild.");
    }
  } catch (error) {
    console.log("create avatar post api faild.", error);
  }

  return false;
};
const pollAvatarAPI = async (file_name, retries = 5, delay = 10000) => {
  for (let attempt = 0; attempt < retries; attempt++) {
    const baseName = file_name.slice(0, file_name.lastIndexOf('.'));
    const avatarFileName = `${baseName}_avatar.png`;
    const API_URL = process.env.REACT_APP_GET_AVATAR_BANNER;
    const userData = JSON.parse(localStorage.getItem("userData"));

    const config = {
      headers: {
        'Authorization': userData ? userData.idToken : "",
        'x-op': 'get-image',
      },
      maxBodyLength: Infinity,
    };

    const requestUrl = `${API_URL}/${avatarFileName}`;

    try {
      const response = await axios.get(requestUrl, config);

      // Assuming the API returns a URL or success code when the avatar is found
      if (response.status === 200 && response.data.Code === "Success") {
        return response.data.Url; // Return the avatar URL when found
      }

      console.log(`Attempt ${attempt + 1}: Avatar not found yet. Retrying in ${delay / 1000} seconds...`);
    } catch (error) {
      console.log(`Attempt ${attempt + 1} failed:`, error);
    }

    // Wait for the delay before the next attempt
    await new Promise((resolve) => setTimeout(resolve, delay));
  }

  // If we reach here, it means the avatar wasn't found after all retries
  return false;
};

//******************
// create the banner
// *****************
export const createBanner = async (degree, file_name) => {
  const API_URL = process.env.REACT_APP_CREATEBANNER_POST
  const userData = JSON.parse(localStorage.getItem("userData"))
  const data = JSON.stringify({
    "degree": degree,
    "file_name": file_name
  });

  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: API_URL,
    headers: {
      'Authorization': userData ? userData.idToken : "",
      'x-op': 'create-banner',
      'Content-Type': 'application/json'
    },
    data: data
  };

  try {
    const response = await axios.request(config);

    if (response.status === 200 && response.data.Code === "Success") {
      // Start polling for avatar and banner
      // const avatarUrlPromise = pollAvatarAPI(file_name);
      const bannerUrlPromise = pollBannerAPI(file_name);

      const [bannerUrl] = await Promise.all([bannerUrlPromise]);

      if (!bannerUrl) {
        return false;
      }

      return { "bannerUrl": bannerUrl };
    } else {
      console.log("Failed to create avatar. Response data:");
    }
  } catch (error) {
    console.log("Error occurred while creating avatar:", error);
  }

  return false;
};
const pollBannerAPI = async (file_name, retries = 5, delay = 10000) => {
  for (let attempt = 0; attempt < retries; attempt++) {
    const baseName = file_name.slice(0, file_name.lastIndexOf('.'));
    const avatarFileName = `${baseName}_banner.png`;
    const API_URL = process.env.REACT_APP_GET_AVATAR_BANNER;
    const userData = JSON.parse(localStorage.getItem("userData"));

    const config = {
      headers: {
        'Authorization': userData ? userData.idToken : "",
        'x-op': 'get-image',
      },
      maxBodyLength: Infinity,
    };

    const requestUrl = `${API_URL}/${avatarFileName}`;

    try {
      const response = await axios.get(requestUrl, config);

      // Assuming the API returns a URL or success code when the avatar is found
      if (response.status === 200 && response.data.Code === "Success") {
        return response.data.Url; // Return the avatar URL when found
      }

      console.log(`Attempt ${attempt + 1}: Avatar not found yet. Retrying in ${delay / 1000} seconds...`);
    } catch (error) {
      console.log(`Attempt ${attempt + 1} failed:`, error);
    }

    // Wait for the delay before the next attempt
    await new Promise((resolve) => setTimeout(resolve, delay));
  }

  // If we reach here, it means the avatar wasn't found after all retries
  return false;

};


// const pollBannerAPI = (file_name) => {
//   const API_URL = process.env.REACT_APP_GET_AVATAR_BANNER


//   const userData = JSON.parse(localStorage.getItem("userData"))
//   return new Promise((resolve) => {
//     const intervalId = setInterval(async () => {
//       try {
//         const baseName = file_name.slice(0, file_name.lastIndexOf('.'));
//         const bannerFileName = `${baseName}_banner.png`;

//         // Configuration for the API request
//         const config = {
//           method: 'get',
//           maxBodyLength: Infinity,
//           url: `${API_URL}/${bannerFileName}`,
//           headers: {
//             'Authorization': userData ? userData.idToken : "",
//             'x-op': 'get-image'
//           }
//         };

//         // Making the API request
//         const response = await axios.request(config);

//         if (response.status === 200 && response.data.Code === "Success") {
//           console.log("Banner API succeeded:");

//           // Stop the polling
//           clearInterval(intervalId);

//           // Resolve the promise with the URL
//           resolve(response.data.Url || false);
//         } else {
//           console.log("Banner API did not succeed yet. Retrying...");
//         }
//       } catch (error) {
//         console.log("Error occurred while calling Banner API:", error);
//       }
//     }, 10000); // Poll every 10 seconds (10000 milliseconds)
//   });
// };




// getBlogs
export const getBlogs = async () => {
  const API_URL = process.env.REACT_APP_GET_BLOGS
  // const API_KEY = process.env.REACT_APP_GET_BLOGS_API_KEY
  const userData = JSON.parse(localStorage.getItem("userData"))

  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: API_URL,
    headers: {
      'Authorization': userData ? userData.idToken : "",
    }
  };

  try {
    const response = await axios.request(config);

    if (response.status === 200) {
      localStorage.setItem("blogs", JSON.stringify(response.data.articles))
      return response.data.articles;  // Return the data when the response is successful
    } else {
      return false;  // Return false if the response is not 200
    }
  } catch (error) {
    return false;  // Return false if there is an error
  }
};




// Share profile URl API 

export const shareProfileUrl = async (username) => {

  const API_URL = process.env.REACT_APP_GET_USER_PROFILE
  const API_KEY = process.env.REACT_APP_GET_USER_PROFILE_API_KEY


  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${API_URL}${username}`,
    headers: {
      'x-api-key': API_KEY,
      'x-op': 'get-user-profile'
    }
  };

  try {
    const response = await axios.request(config);

    // Check if the status is 200 (OK)
    if (response.status === 200) {
      // Check if Items array exists and has length > 0
      if (response.data.Items && response.data.Items.length > 0) {
        return response.data.Items; // Return the Items if present
      } else {
        return "no user"; // Return message if no user is found
      }
    } else {
      return false; // Return false if status is not 200
    }
  } catch (error) {
    return false; // Return false in case of error
  }


}



//get api to get the avatar and the banner images 
export const getAvatarBannerImages = async (filename) => {
  const userData = JSON.parse(localStorage.getItem("userData"))
  const baseName = filename.slice(0, filename.lastIndexOf('.'));
  const bannerFileName = `${baseName}_banner.png`;
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `https://ue58z1vdr4.execute-api.ap-south-1.amazonaws.com/api_dev/file/${bannerFileName}`,
    headers: {
      'Authorization': userData ? userData.idToken : "",
      'x-op': 'get-image'
    }
  };

  try {
    const result = await axios.request(config);
    if (result.status === 200) {
      return result.data.Url
    }
    else if (result.status == 403) {
      return "noUrl"
    }
  } catch (error) {
    return false
  }
}


//get api to get the avatar and the banner images 
export const getAvatarProfileImage = async (filename) => {
  const userData = JSON.parse(localStorage.getItem("userData"))
  const baseName = filename.slice(0, filename.lastIndexOf('.'));
  const avatarFileName = `${baseName}_avatar.png`;
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `https://ue58z1vdr4.execute-api.ap-south-1.amazonaws.com/api_dev/file/${avatarFileName}`,
    headers: {
      'Authorization': userData ? userData.idToken : "",
      'x-op': 'get-image'
    }
  };

  try {
    const result = await axios.request(config);

    if (result.status === 200) {
      return result.data.Url
    }
    else if (result.status == 403) {
      return "noUrl"
    }
  } catch (error) {
    return false
  }
}


export const getUSerProfileImages = async (filename) => {
  const userData = JSON.parse(localStorage.getItem("userData"))
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `https://ue58z1vdr4.execute-api.ap-south-1.amazonaws.com/api_dev/file/${filename}`,
    headers: {
      'Authorization': userData ? userData.idToken : "",
      'x-op': 'get-image'
    }
  };

  try {
    const result = await axios.request(config);
    if (result.status === 200) {

      return result.data.Url
    }
    else if (result.status === 403) {

      return "noUrl"
    }
  } catch (error) {

    return false
  }

}

////****************
// qrisk api here 
//****************
export const getQriskResult = async (userData,key) => {
  // pass the captcha value
  const diabt = JSON.parse(userData.diabetesStatus)
  const API_URL = process.env.REACT_APP_GET_QRISK
  const API_KEY = process.env.REACT_APP_GET_QRISK_API_KEY
  console.log("API_URL",API_URL)
  console.log("API_KEY".API_KEY)
  try {
    let data = JSON.stringify({
      
      "b_semi":userData.mentalIllness?1:0, //Severe mental illness maybe with psychotic symptoms? checkbox
      "b_atypicalantipsy": userData.antipsychoticMedication?1:0, //atypical antipsychotic medication? checkbox
      "b_sle": userData.sle?1:0, //Systemic lupus erythematosus (SLE)? checkbox
      "b_impotence2": userData.erectileDysfunction?1:0,//A diagnosis of or treatment for erectile disfunction?checkbox
      "b_corticosteroids": userData.steroidTablets?1:0,//Are you on regular steroid tablets?checkbox
      "b_AF": userData.atrialFibrillation?1:0, //Atrial Fibrillation checkbox
      "b_renal": userData.chronicKidneyDisease?1:0, //Chronic kidney disease (stage 3, 4 or 5)?checkbox
      "b_ra": userData.rheumatoidArthritis?1:0, //Rheumatoid arthritis? checkbox
      "b_treatedhyp": userData.bloodPressureTreatment?1:0,//Blood pressure treatment maybe? checkbox
      "b_migraine": userData.migraines?1:0,//Do you have migraines? checkbox
      "fh_cvd": userData.angina?1:0, //Angina or heart attack in a 1st degree relative < 60? checkbox

      "ethrisk": parseInt(userData.ethnicity), //Ethnicity
      "sex": parseInt(userData.sex), //female= 0 or male=1

      "b_type1": diabt.type1, //Type 1 diabetes
      "b_type2": diabt.type2, //Type 2 diabetes


      "b_weight": parseInt(userData.weight), //Weight in kg 40-180
      "b_height":parseInt(userData.height), // Height in cm
      "age": parseInt(userData.age),//25-84

      "sbps5": userData.sbpDeviation===""?10.0:parseFloat(userData.sbpDeviation), //Standard deviation of at least two most recent systolic blood pressure readings (mmHg)
      "rati": userData.cholesterol==="" ?3.5:parseFloat(userData.cholesterol),  //Cholestrol/HDL Ratio maybe?
      "sbp": userData.sbp===""?135:parseInt(userData.sbp), // Systolic blood pressure

      "surv": 0,
      "town": 0,

      "smoke_cat":parseInt(userData.smokingStatus), //Smoking status //smoke_cat = Smoking status [0,1,2,3,4]  '0' = non-smoker  '1' = ex-smoker  '2' = light smoker (less than 10)  '3' = moderate smoker (10 to 19)  '4' = heavy smoker (20 or over)
      "name":userData.username, 
      "phone_no":userData.mobileNumber,
      "recaptha_code":key.toString()
    });

  


    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url:API_URL,
      headers: { 
        'User-Agent': 'AiSteth/2.4 (com.aihealth.AiSteth; build:23; iOS 16.4.0) Alamofire/5.6.4', 
        'Accept-Encoding': 'br;q=1.0, gzip;q=0.9, deflate;q=0.8', 
        'Accept-Language': 'en;q=1.0', 
        'Content-Type': 'application/json', 
        'x-api-key':API_KEY,
      },
      data : data
    };

    const response = await axios.request(config);
    // *****************
    // check the result
    // *****************
    if(response.status === 200){
      return response.data.qrisk_score;
    }
    else if(response.status===400){
      console.error("captcha Token Error")
    }
    else{
      return false
    }
    // Return the response data if needed
  } catch (error) {
    console.error('Error making the API request:', error);
    return false
  }
}


// *****************
// post user detials
// *****************
export const postUserQriskResult = async (data) => {
  return 0.4;
}