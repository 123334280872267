import React, { useState, useEffect } from 'react';
import { Card, Form, Button, InputGroup, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { checkUserProfileCreated, fecthAllColleges, fileUplaod, createUserAccount } from './../api';
import Loading from './Loading';

function UserProfileForm({handleUserFormSubmit}) {


    // all required states
    const [college, setCollege] = useState(null);
    const [degree, setDegree] = useState(null);
    const [image, setImage] = useState(null);
    const [selectedState, setSelectedState] = useState("");
    const [collegesByState, setCollegesByState] = useState({});
    const [errrorMessage, setErrrorMessage] = useState(null);
    const [originalfileName, setOriginalfileName] = useState(null);
    const [savedAsfileName, setSavedAsfileName] = useState(null);
    const [fileUploadResult, setFileUploadResult] = useState({});
    const [loading, setLoading] = useState(true);
    const [gender, setGender] = useState("");
    const [dob, setDob] = useState("");
    const [age, setAge] = useState("");
    const [refral, setRefral]  =useState("")
    
    const navigate = useNavigate();



    useEffect(() => {
        const fetchInitialData = async () => {
            try {

                // calling the fetchall college api for the form fill up 
                const collegeData = await fecthAllColleges()
                setCollegesByState(collegeData)

            } catch (error) {
                // navigate or show the profile form because cant access the profile page.
                console.error("get profile api error")
            }
            finally {
                setLoading(false)
            }
        }
        fetchInitialData()
    }, [navigate])


    // Medical degree -> add new
    const medicalDegrees = [
        "Doctor of Medicine (MD)",
        "Bachelor of Medicine, Bachelor of Surgery (MBBS)",
        "Doctor of Osteopathic Medicine (DO)",
        "Master of Surgery (MS)",
        "Doctor of Dental Surgery (DDS)",
        "Doctor of Dental Medicine (DMD)",
        "Master of Public Health (MPH)",
        "Doctor of Philosophy (PhD) in Medical Sciences",
        "Master of Clinical Medicine (MCM)",
        "Bachelor of Science in Nursing (BSN)",
        "Doctor of Nursing Practice (DNP)",
        "Master of Science in Nursing (MSN)",
        "Master of Health Administration (MHA)",
        "Doctor of Pharmacy (PharmD)",
        "Master of Medical Science (MMSc)",
        "Doctor of Physical Therapy (DPT)",
        "Master of Science in Physician Assistant Studies (MSPAS)",
        "Fellowship in various medical specialties",
        "Diplomate of National Board (DNB)",
        "Master of Science (MSc) in Medical Genetics, Microbiology, etc.",
    ];

    // College Options
    const collegeOptions = selectedState ? collegesByState[selectedState] : [];


    // Function to calculate age from DOB
    const calculateAge = (dob) => {
        const birthDate = new Date(dob);
        const today = new Date();
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        // Adjust age if the birthdate has not yet occurred this year
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            return age - 1;
        }
        return age;
    };

    // Function to handle DOB change
    const handleDobChange = (e) => {
        const selectedDob = e.target.value;
        setDob(selectedDob);

        const age = calculateAge(selectedDob);
        setAge(age);
        if (age < 18) {
            setErrrorMessage('Age must be greater than 18.');
        } else {
            setErrrorMessage('');
        }
    };

    const handleSubmit = async (e) => {
        
        e.preventDefault();
    
        // step 1 
        // all fields required 
        if (!college || !degree || !image || !dob || !gender) {
          setErrrorMessage("Please fill out all required fields.")
        }
        else {
          try {
            setErrrorMessage("")
            setLoading(true)
    
            // Step 1: first upload file to s3 and get the filename
            const LocalfileName = image.name;
    
            // Step 2: Convert file reading to a promise to use with async/await
            const readFileAsArrayBuffer = (file) => {
              return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result); // Resolves with the ArrayBuffer
                reader.onerror = (error) => reject(error);    // Rejects with an error
                reader.readAsArrayBuffer(file);
              });
            };
    
            // Step 3: Wait for the file to be read as an ArrayBuffer
            const arrayBuffer = await readFileAsArrayBuffer(image);
    
            // Step 4: Call the uploadFile function and await its result

            const result = await fileUplaod(LocalfileName, arrayBuffer);
      
            
            // Step 5: Use the result to create the user account
            // this function in api.js will call the post api 
            // 
            const accountCreated = await createUserAccount(
              
              result.original_filename,
              result.saved_as,
              college,
              degree,
              dob,
              gender,
            );
    
            if(!accountCreated){
              console.log("account creation post api error")
            }
            if (accountCreated) {
              setLoading(false)
              // call the put api and update the detials

              // call the avatar api 
                // if avatar created set the related states here 
                // profile image 
                // original avatar 
                // allowavatar false 


              // call the banner api 
                // if banner created set banner 
                // else set defaut 

              navigate('/doctorProfile')
            }
    
          } catch (error) {
            console.error("fileupload or createaccount api error >> ", error)
          }
    
        }
      }


    // handle the form sublit 
    const handleUserSubmit = (e) => {
            e.preventDefault();
              if (!college || !degree || !image || !dob || !gender) {
            setErrrorMessage("Please fill out all required fields.")
        }
            handleUserFormSubmit({"college":college, "degree":degree, "image":image, "gender":gender, "dob":dob,"age":age})
    }

    return (
        <>
        {
          loading ? <div>
            <Loading />
          </div> :
            <Card className="p-4 shadow" style={{ width: '100%', maxWidth: '400px' }}>
              <Card.Body>
                <Form>
                  {/* Gender Field */}
                  <Form.Group controlId="formGender" className="mb-4">
                    <InputGroup>
                      <Form.Control
                        as="select"
                        onChange={(e) => setGender(e.target.value)}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          Select Gender
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="other">Other</option>
                      </Form.Control>
                    </InputGroup>
                  </Form.Group>
  
                  {/* Date of Birth Field */}
                  <Form.Group controlId="formDOB" className="mb-4">
                    <InputGroup>
                      <Form.Control
                        type="date"
                        onChange={handleDobChange}
                      />
                    </InputGroup>
                  </Form.Group>
  
  
  
  
                  {/* State and College Selection in the same line */}
                  <Row className="mb-4">
                    <Col md={4}>
                      <Form.Group controlId="formState">
                        <InputGroup>
                          <Form.Control
                            as="select"
                            onChange={(e) => setSelectedState(e.target.value)}
                            value={selectedState}
                          >
                            <option value="">State</option>
                            {Object.keys(collegesByState).map((state) => (
                              <option key={state} value={state}>
                                {state}
                              </option>
                            ))}
                          </Form.Control>
                        </InputGroup>
                      </Form.Group>
                    </Col>
  
                    <Col md={8}>
                      <Form.Group controlId="formCollegeName">
                        <InputGroup>
                          <Form.Control
                            as="select"
                            onChange={(e) => setCollege(e.target.value)}
                            value={college}
                            disabled={!selectedState}
                          >
                            <option value="">Select College Name</option>
                            {collegeOptions.map((collegeObj) => (
                              <option key={collegeObj.name} value={collegeObj.name}>
                                {collegeObj.name}
                              </option>
                            ))}
                          </Form.Control>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
  
                  <Form.Group controlId="formDegree" className="mb-4">
                    <InputGroup>
                      <Form.Control as="select" onChange={(e) => setDegree(e.target.value)}>
                        <option value="">Select Degree</option>
                        {medicalDegrees.map((degree, index) => (
                          <option key={index} value={degree}>
                            {degree}
                          </option>
                        ))}
                      </Form.Control>
                    </InputGroup>
                  </Form.Group>
  
  
  
                  <Form.Group controlId="formProfileImage" className="mb-4">
                    <InputGroup>
                      <Form.Control type="file" accept="image/*" onChange={(e) => setImage(e.target.files[0])} />
                    </InputGroup>
                    <Form.Text className="text-muted">
                      Please upload a clear image that will be used as your profile picture.
                    </Form.Text>
                  </Form.Group>
  
                  {/* refral code field */}
  
                  <div className="d-flex justify-content-between">
              
                    <Button
                      variant="primary"
                      className="w-100"
                      style={{ backgroundColor: '#6f52ed', borderColor: '#6f52ed' }}
                      type="submit"
                      onClick={handleUserSubmit}
                    >
                      Submit Details
                    </Button>
                  </div>
  
                  {/* empty form error */}
                  {errrorMessage && (<div className='mt-3 bg-danger text-light rounded text-center'><small>{errrorMessage}</small></div>)}
                </Form>
  
                <div className="text-center mt-4">
                  <small className="text-muted">
                    By continuing, you agree to{' '}
                    <a href="#" className="text-decoration-none">Terms of service</a> and{' '}
                    <a href="#" className="text-decoration-none">Privacy Policy</a>.
                  </small>
                </div>
              </Card.Body>
            </Card>
        }
      </>
  
    )
}

export default UserProfileForm