import React, { useState } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { Container, Image, Dropdown, Button } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './../App.css';
import { Auth } from 'aws-amplify';


function Layout() {
  const navigate = useNavigate();
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  // Get user data from localStorage
  const storedUserData = JSON.parse(localStorage.getItem('userData'));

  // Function to handle logout
  const handleLogout = () => {
    if (storedUserData) {
      Auth.signOut()
        .then(() => {
          localStorage.removeItem('userData');
          localStorage.removeItem('userprofile');
          localStorage.removeItem('blogs');
          navigate('/');
        })
        .catch((err) => console.error(err));
    }
   
  };

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="/">
            <Image 
              src={`${process.env.PUBLIC_URL}/profileimages/logo.jpeg`} 
              thumbnail fluid alt="Logo" 
              style={{ height: '70px', width: 'auto' }} 
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link className='mx-4 nav-link d-none' to="/blogs">Contents</Link>
              <Link className='mx-4 nav-link' to="/qrisk">Qrisk</Link>
            </Nav>

            {/* Profile Icon and Menu */}
            {storedUserData && (
              <div style={{ position: 'relative' }}>
                <Image
                  src={`${process.env.PUBLIC_URL}/profileimages/user.png`} 
                  alt="Profile Icon"
                  onClick={() => setShowProfileMenu(!showProfileMenu)}
                  style={{
                    width: '40px',
                    height: '40px',
                    cursor: 'pointer',
                    borderRadius: '50%',
                  }}
                />
                {showProfileMenu && (
               <div
               style={{
                 position: 'absolute',
                 right: 0,
                 background: '#f8f9fa', // Light background for a clean look
                 border: '1px solid #ddd',
                 padding: '20px',
                 borderRadius: '12px', // More rounded corners for a modern feel
                 boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)', // Stronger shadow for better depth
                 zIndex: 1000,
                 display:"inline-block",
                 top: '50px', // Adjusted to ensure the popup appears below the icon

               }}
             >
               <div
                 style={{
                   display: 'flex',
                   alignItems: 'center',
                   marginBottom: '15px',
                   minWidth: '250px', // Ensures a minimum width
                   
                 }}
               >
                 <Image
                   src={`${process.env.PUBLIC_URL}/profileimages/user2.png`}
                   alt="Profile"
                   style={{
                     width: '30px',
                     height: '30px',
                     borderRadius: '50%',
                     marginRight: '15px',
                   }}
                 />
                 <div>
                   <p style={{ margin: 0, fontWeight: 'bold', fontSize: '1.1rem', color: '#333' }}>
                     {storedUserData.username}
                   </p>
      
                 </div>
               </div>
             
               <Button
                 variant="danger"
                 onClick={handleLogout}
                 style={{
                   width: '100%',
                   backgroundColor: '#d9534f',
                   borderColor: '#d43f3a',
                   padding: '10px',
                   fontSize: '1rem',
                 }}
               >
                 Logout
               </Button>
             </div>
             
                )}
              </div>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      
      <div style={{ height: 'calc(100vh - 80px)' }}>
        <Outlet />
      </div>
    </>
  );
}

export default Layout;
