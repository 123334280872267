import React from 'react'
import { FaUniversity, FaBriefcase, FaUserFriends, FaPhone, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa';
import { Badge } from 'react-bootstrap';
import { Container, Row, Col, Card } from 'react-bootstrap';
function ShareUserProfile({profileData}) {
  const maskLogin = (login) => {
    if (!login || login === "NA") {
      return "No Data";
    }
    
    // Check if the login is an email
    if (login.includes('@')) {
      const [user, domain] = login.split('@');
      return user.length > 2
        ? `${user.slice(0, 2)}${'*'.repeat(user.length - 2)}@${domain}`
        : `${user}@${domain}`;
    } 
    // Check if the login is a phone number (assuming phone number is numeric and at least 10 digits)
    else if (/^\d+$/.test(login)) {
      return `${'*'.repeat(login.length - 4)}${login.slice(-4)}`;
    }
    
    return login; // In case it's neither email nor phone number
  };


  return (
    <Row className='d-flex flex-column align-items-center'>
      <Col md={10} className='text-center mt-4 p-4 border rounded shadow-lg'>

        <h4>{profileData.name}</h4>
        <p className="text-muted"><span className="badge bg-secondary">{(profileData.qualification == "NA" || profileData.qualification == "") ? "No Data" : profileData.qualification}</span> | <span className="badge bg-secondary">{(profileData.institute_name == "" || profileData.institute_name == "NA") ? "No Data" : profileData.institute_name}</span></p>
        {/* <p className="text-muted">
          <span className="badge bg-danger">Experience: {(profileData.year_of_experience =="NA" || profileData.year_of_experience =="") ? "No Data" : profileData.year_of_experience  }</span>
        </p> */}
        <p className="text-muted">
           <FaEnvelope className="me-2 text-success" /> {maskLogin(profileData.login)}
        </p>
        {/* <p className="text-muted">
          <FaPhone className="me-2 text-success" /> {profileData.phone}
        </p> */}
        <p className="text-muted"><FaMapMarkerAlt className="me-2 text-primary" />Address: <span className="text-muted">{(profileData.address != "" || profileData.address != "NA" ) ? profileData.address : "No Data"}</span></p> {/* Placeholder for address */}

      </Col>
    </Row>



  )
}

export default ShareUserProfile