import React from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';

const AlertCard = ({ variant, heading, message, onClose }) => {
    const variantClass = `border-${variant} text-${variant}`; // Dynamically assign variant classes

    return (
        <Row className="justify-content-center mt-4">
            <Col  md={6} >
                <Card className={`mt-3 ${variantClass}`}>
                    <Card.Header as="h5">{heading}</Card.Header>
                    <Card.Body>
                        <Card.Text>{message}</Card.Text>
                        {/* {onClose && (
                            <Button variant={variant} onClick={onClose}>
                                Close
                            </Button>
                        )} */}
                    </Card.Body>
                </Card>
            </Col>

        </Row>

    );
};

export default AlertCard;
