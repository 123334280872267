import React from 'react'
import './../App.css';
function ProfileBanner({banner, profileImage}) {
  
  return (
    <div className="position-relative">
      {/* Banner Image */}
      {/* Banner Image */}
      <div style={{ height: '450px', overflow: 'hidden' }} className='bg-dark'>
        <img
          src={banner} // Replace this with the URL of your banner image
          alt="Banner"
          style={{ width: '100%', height: '100%', objectFit: 'fill' }}
        />
      </div>

      {/* Profile Image */}
      <div className="position-absolute top-100 start-50 translate-middle ">
        <img
          src={profileImage}
          alt="Profile"
          className="rounded-circle border border-secondary shadow-lg"
          style={{ width: '150px', height: '150px', marginTop: '0px' }}
        />
      </div>
    </div>
  )
}

export default ProfileBanner