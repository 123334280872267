
import './App.css';
import  Home from './pages/Home';
import  Layout from './pages/Layout';
import Blogs from './pages/Blogs';
import Login from './pages/Login';
import ProfilePage from './pages/ProfilePage';
import ProfileCreationForm from './pages/ProfileCreationForm'
import TestSignUp from './pages/TestSignUp'
import Test from './Components/Test';
import  PageNotFound from './pages/PageNotFound';
import ShareProfileUrl from './pages/ShareProfileUrl';
import ShareProfileUrl2  from './pages/ShareProfileUrl2';
import ProtectedRoutes from './pages/ProtectedRoutes';
import QRiskForm from './pages/QRiskForm';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';
import { useEffect } from 'react';


// google analystics fucntion/ compoenent 
const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    console.log("Tracking Component Success")
  }, [location]);

  return null;
};


function App() {
  // google tracking
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
    console.log("GA initialized Success")
  },[])

  return (
    <div className="App">
     <BrowserRouter>
     {/* google analystics */}
     <TrackPageView /> 
      <Routes>
        <Route path="/" element={<Layout />}> 
        <Route element= {<ProtectedRoutes />}>
        <Route path="profileform" element={<ProfileCreationForm />} />
        <Route path="doctorprofile" element={<ProfilePage />}  />
        </Route>
          <Route index element={<Login />} />
          <Route path="home" element={<Home />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="test" element={<TestSignUp />} />
          <Route path="qrisk" element={<QRiskForm />} />
          <Route path="/profile/:username/:article_number?" element={<ShareProfileUrl2 />} />
          {/* <Route path="/profile/test" element={<ShareProfileUrl2 />} /> */}
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
