import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';  // Import react-helmet for dynamic meta tags
import Card from 'react-bootstrap/Card';
import { FaShareAlt, FaSkype, FaTwitter, FaLinkedin, FaWhatsapp, FaEnvelope } from 'react-icons/fa'; // Import icons
import { useNavigate } from 'react-router-dom';

function BlogsCard({ article_number, image, title, date, summary, redirectLink }) {
  const navigate = useNavigate();
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [isUserLogIn, setIsUserLogIn] = useState(false);
  const shareRef = useRef(null);
  const [username, setUsername] = useState(null); 

  // Open Graph dynamic meta tags generator
  const metaTags = {
    title,
    description: summary,
    image: image || `${process.env.PUBLIC_URL}/profileimages/logo.jpeg`,
    // url: `https://your-website.com/profile/${username}/${article_number}` // base url change
    // url: `http://13.201.26.62/profile/${username}/${article_number}` // base url change
    url: `https://www.billionheart.com/profile/${username}/${article_number}` // base url change
  };

  function handleReadMore(e) {
    e.preventDefault();
    window.open(redirectLink, '_blank', 'noopener,noreferrer');
  }

  function toggleShare() {
    setIsShareOpen(!isShareOpen);
  }

  function generateShareURL() {
    // const baseURL = "http://localhost:3000/profile";
    const baseURL = "http://43.205.136.201/profile";
    return `${baseURL}/${username}/${article_number}`;
  }

  function shareOn(platform) {
    const shareURL = generateShareURL(); 
    let url = '';
    switch (platform) {
      case 'skype':
        url = `https://web.skype.com/share?url=${encodeURIComponent(shareURL)}`;
        break;
      case 'gmail':
        url = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(shareURL)}`;
        break;
      case 'whatsapp':
        url = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareURL)}`;
        break;
      case 'twitter':
        url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareURL)}&text=${encodeURIComponent(title)}`;
        break;
      case 'linkedin':
        url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareURL)}&title=${encodeURIComponent(title)}`;
        break;
      default:
        break;
    }
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  useEffect(() => {
    const userPresent = JSON.parse(localStorage.getItem('userprofile')); 

    if (!userPresent) {
      setIsUserLogIn(false);
    } 
    else if (userPresent._sourceObject) {
      setIsUserLogIn(true);
      setUsername((userPresent._sourceObject.username !== "" && userPresent._sourceObject.username !== "NA") ? userPresent._sourceObject.username : "unknown");
    } 
    else if (userPresent.username === "Default User") {
      setIsUserLogIn(false);
    }
    

    function handleClickOutside(event) {
      if (shareRef.current && !shareRef.current.contains(event.target)) {
        setIsShareOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* Helmet to add dynamic Open Graph meta tags */}
      <Helmet>
        <meta property="og:title" content={metaTags.title} />
        <meta property="og:description" content={metaTags.description} />
        <meta property="og:image" content={metaTags.image} />
        <meta property="og:url" content={metaTags.url} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Your Blog Name" />
      </Helmet>

      <Card className="blog-card mx-auto my-3">
        <Card.Img
          variant="top"
          // src={image || './logo.jpeg'}
          src={image || './logo.jpeg'}
          alt="Blog Image"
          className="card-image"
        />
        <Card.Body className="d-flex flex-column">
          <p className='d-none'>{article_number}</p>
          <Card.Title className="card-title">{title}</Card.Title>
          <Card.Text className="text-muted card-date">
            <small>{date}</small>
          </Card.Text>
          <Card.Text className="card-summary">
            {summary}
          </Card.Text>
          <div className="d-flex justify-content-between mt-auto">
            <button
              onClick={handleReadMore}
              className="btn btn-primary btn-sm card-btn"
            >
              Read More
            </button>

            {isUserLogIn ?
              <div className="share-container mx-2" ref={shareRef}>
                <button
                  onClick={toggleShare}
                  className="btn btn-outline-secondary btn-sm share-btn"
                >
                  <FaShareAlt />
                </button>
                {isShareOpen && (
                  <div className="share-options">
                    <button onClick={() => shareOn('skype')} className="btn btn-link">
                      <FaSkype />
                    </button>
                    <button onClick={() => shareOn('gmail')} className="btn btn-link">
                      <FaEnvelope />
                    </button>
                    <button onClick={() => shareOn('whatsapp')} className="btn btn-link">
                      <FaWhatsapp />
                    </button>
                    <button onClick={() => shareOn('twitter')} className="btn btn-link">
                      <FaTwitter />
                    </button>
                    <button onClick={() => shareOn('linkedin')} className="btn btn-link">
                      <FaLinkedin />
                    </button>
                  </div>
                )}
              </div> : <p></p>}
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default BlogsCard;
