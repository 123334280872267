import React from 'react';
import { Container, Alert } from 'react-bootstrap';

function NoContent() {
  return (
    <Container className="my-5">
    <Alert variant="warning" className="text-center">
      No blogs available at the moment.
    </Alert>
  </Container>
  )
}

export default NoContent