import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, Container, Row, Col, Badge, Modal, Spinner } from 'react-bootstrap';
import { FaUser, FaGenderless, FaLungs, FaSyringe, FaHeartbeat, FaRulerVertical, FaWeight, FaInfoCircle } from 'react-icons/fa';
import { getQriskResult, postUserQriskResult } from '../api.js';
import ReCAPTCHA from 'react-google-recaptcha'; // recaptcha
import { Tooltip } from 'react-tooltip';



function QRiskForm() {
  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState(null);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const generateSmileyGrid = (qriskScore) => {
    const totalSmileys = 100; // 10x10 grid = 100 smileys
    const sadSmileysCount = Math.round(qriskScore); // QRisk score is out of 100, so use it directly for sad faces
    const happySmileysCount = totalSmileys - sadSmileysCount;

    const smileys = [];

    // Add sad smileys
    for (let i = 0; i < sadSmileysCount; i++) {
      smileys.push(
        <span key={`sad-${i}`} style={{ fontSize: '24px', margin: '0 5px', padding: "1px" }}>
          <img style={{ height: "30px", width: "30px" }} src={`${process.env.PUBLIC_URL}/profileimages/sad_final.png`} alt="sad" />
        </span>
      );
    }

    // Add happy smileys
    for (let i = 0; i < happySmileysCount; i++) {
      smileys.push(
        <span key={`happy-${i}`} style={{ fontSize: '24px', margin: '0 5px', padding: "1px" }}>
          <img style={{ height: "30px", width: "30px" }} src={`${process.env.PUBLIC_URL}/profileimages/happy_final.webp`} alt="happy" />
        </span>
      );
    }

    // Shuffle the smileys array randomly
    const shuffledSmileys = smileys.sort(() => Math.random() - 0.5);

    // Create a grid layout for the smileys
    const smileyGrid = [];
    for (let row = 0; row < 10; row++) {
      const rowSmileys = shuffledSmileys.slice(row * 10, row * 10 + 10); // Slice 10 smileys per row
      smileyGrid.push(
        <div key={row} style={{ display: 'flex', justifyContent: 'center', margin: '5px 0' }}>
          {rowSmileys}
        </div>
      );
    }

    return smileyGrid;
  };

  // *********************
  // post the user detials 
  // *********************
  const postUserDetials = async (data) => {
    const result = await postUserQriskResult(data);
    //try catch
    if (result) {
      console.log("");
    }
    else {
      console.log("")
    }
  }

  //***********************
  // api ti fetch the qrisk
  //***********************
  const fetchQrisk = async (data, key) => {
    setLoading(true)
    const qriskScore = await getQriskResult(data, key)
    if (qriskScore) {
      const roundedRiskScore = Math.round(qriskScore);
      const smileyGrid = generateSmileyGrid(qriskScore);
      const riskMessage = (
        <div>
          <p>Your risk of having a heart attack or stroke within the next 10 years is <strong>{qriskScore}%</strong>.</p>
          <p>In other words, in a crowd of 100 people with the same risk factors as you, <strong>{roundedRiskScore}</strong> are likely to have a heart attack or stroke within the next 10 years.</p>
        </div>
      );

      setPopupContent(
        <div>
          {riskMessage}
          <div className="d-flex flex-wrap justify-content-center border-primary" style={{ borderWidth: '2px', borderStyle: 'solid'}}>
            {smileyGrid}
          </div>
        </div>
      );
      setShowPopup(true);
    }
    setLoading(false)
    console.log("GET QRISK API ERROR, try again msg here")
  }
  // *************
  // handle submit
  // ************* 
  const onSubmit = (data, event) => {

    // once the user submit the form we have to hit the api here 
    // once the response is success then hit the another api where will pass the scoure and the user details. 
    event.preventDefault();
    if (recaptchaValue) {
      fetchQrisk(data, recaptchaValue)
    }
    else {
      alert("Please complete the reCAPTCHA")
      setLoading(false)
    }

  };


  // ***********
  // close popup
  // ***********
  const handleClose = () => setShowPopup(false);


  const formStyle = {
    margin: '2rem auto',
    padding: '2rem',
    border: '1px solid #ccc',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f9f9f9',
  };

  const headingStyle = {
    textAlign: 'center',
    marginBottom: '2rem',
    color: '#333',
    fontWeight: 'bold',
    fontSize: '1.5rem',
  };

  const labelStyle = {
    fontWeight: 'bold',
    color: '#555',
  };

  const iconStyle = {
    marginRight: '8px',
    color: '#007bff',
  };

  const badgeStyle = {
    marginLeft: '10px',
    fontSize: '12px',
  };

  const buttonStyle = {
    display: 'block',
    width: '100%',
    marginTop: '1.5rem',
    padding: '0.75rem',
    backgroundColor: '#007bff',
    borderColor: '#007bff',
    color: '#fff',
    fontWeight: 'bold',
  };

  const fieldStyle = {
    marginBottom: '1.5rem',
  };

  return (
    <Container>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <div style={formStyle}>

            <div className="d-flex justify-content-center">
              <h2 style={headingStyle} className="d-flex align-items-center">
                QRISK
                <FaInfoCircle
                  data-tooltip-id="qrisk-info"
                  className="text-primary ms-2"
                  style={{ fontSize: "18px", cursor: "pointer" }}
                />
              </h2>

              {/* Tooltip */}
              <Tooltip
                id="qrisk-info"
                place="top"
                effect="float"
                style={{
                  backgroundColor: "#343a40", // Dark background
                  color: "white", // White text
                  borderRadius: "5px", // Rounded corners
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow
                  maxWidth: "300px", // Max width for the tooltip
                  padding: "1rem", // Padding for the content
                  fontSize: "0.9rem", // Font size for better readability
                }}
              >
                <strong>QRISK Score:</strong>
                <p className='py-2'>
                  The QRISK algorithm calculates a person's risk of developing a heart attack or stroke over the next 10 years. It presents the average risk of people with the same risk factors as those entered for that person.
                </p>
                <strong>Note:</strong>
                <ul>
                  <li className='py-1'>You can use QRISK if you are aged between 25 and 84 years.</li>
                  <li className='py-1' >If you have had a heart attack, angina, stroke, or transient ischemic attack, this risk calculator is not recommended for you.</li>
                  <li className='py-1'>If you are concerned about your estimated risk, then you should consult a doctor or nurse for a full risk assessment.</li>
                </ul>
              </Tooltip>
            </div>

            {/* 
            <h2 style={headingStyle}>
              
            </h2> */}
            <Form onSubmit={handleSubmit((data, event) => onSubmit(data, event))}>

              {/* Username */}
              <Form.Group as={Row} controlId="username" style={fieldStyle}>
                <Form.Label column sm="4" style={labelStyle}>
                  <FaUser style={iconStyle} /> Name:
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="text"
                    {...register('username', {
                      required: true,
                      pattern: {
                        value: /^[A-Za-z\s]+$/,  // Allows only letters and spaces
                        message: "Name can only contain letters."  // Custom error message
                      }
                    })}
                    placeholder="Enter your Name"
                    isInvalid={!!errors.username}
                  />
                  {errors.username && (
                    <Form.Control.Feedback type="invalid">
                      {errors.username.message || "Name is required."}
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group>


              {/* Mobile Number */}
              <Form.Group as={Row} controlId="mobileNumber" style={fieldStyle}>
                <Form.Label column sm="4" style={labelStyle}>
                  <FaUser style={iconStyle} /> Mobile Number:
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="tel"
                    {...register('mobileNumber', {
                      required: true,
                      pattern: /^[0-9]{10}$/, // Validates a 10-digit number
                    })}
                    placeholder="Enter your mobile number"
                    isInvalid={!!errors.mobileNumber}
                  />
                  {errors.mobileNumber && (
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid 10-digit mobile number.
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group>
              {/* Age */}
              <Form.Group as={Row} controlId="age" style={fieldStyle}>
                <Form.Label column sm="4" style={labelStyle}>
                  <FaUser style={iconStyle} /> Age (25-84):
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="number"
                    {...register('age', { required: true, min: 25, max: 84 })}
                    placeholder="Enter your age"
                    isInvalid={!!errors.age}
                  />
                  {errors.age && (
                    <Form.Control.Feedback type="invalid">
                      Age is required and must be between 25 and 84.
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group>

              {/* Sex */}
              <Form.Group as={Row} controlId="sex" style={fieldStyle}>
                <Form.Label column sm="4" style={labelStyle}>
                  <FaGenderless style={iconStyle} /> Sex:
                </Form.Label>
                <Col sm="8">
                  <Form.Check
                    type="radio"
                    label="Male"
                    value="1"  // Set value as 1 for male
                    {...register('sex', { required: true })}  // Register for validation
                    name="sex"
                    inline
                  />
                  <Form.Check
                    type="radio"
                    label="Female"
                    value="0"  // Set value as 0 for female
                    {...register('sex', { required: true })}  // Register for validation
                    name="sex"
                    inline
                  />
                  {errors.sex && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      Sex is required.
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group>


              {/* Ethnicity */}
              <Form.Group as={Row} controlId="ethnicity" style={fieldStyle}>
                <Form.Label column sm="4" style={labelStyle}>
                  <FaUser style={iconStyle} /> Ethnicity:
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    as="select"
                    {...register('ethnicity', { required: true })}
                    isInvalid={!!errors.ethnicity}
                  >
                    <option value="2">India</option>
                    <option value="0">White</option>
                    <option value="3">Pakisthani</option>
                    <option value="4">Bangladeshi</option>
                    <option value="5">Other Asian</option>
                    <option value="6">Black Caribbean</option>
                    <option value="7">Black African</option>
                    <option value="8">Chinese</option>
                    <option value="9">Other ethnic group</option>
                  </Form.Control>

                  {errors.ethnicity && (
                    <Form.Control.Feedback type="invalid">
                      Ethnicity is required.
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group>

              {/* Postcode */}
              {/* <Form.Group as={Row} controlId="postcode" style={fieldStyle}>
                <Form.Label column sm="4" style={labelStyle}>
                  <FaUser style={iconStyle} /> UK Postcode:
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="text"
                    {...register('postcode')}
                    placeholder="Leave blank if unknown"
                  />
                </Col>
              </Form.Group> */}

              {/* Clinical Information */}
              <h4 style={headingStyle}>
                Clinical Information <Badge bg="info" style={badgeStyle}>Important</Badge>
              </h4>

              {/* Smoking Status */}
              <Form.Group as={Row} controlId="smokingStatus" style={fieldStyle}>
                <Form.Label column sm="4" style={labelStyle}>
                  <FaLungs style={iconStyle} /> Smoking Status:
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    as="select"
                    {...register('smokingStatus', { required: true })}  // Required field validation
                    isInvalid={!!errors.smokingStatus}
                  >
                    <option value="0">Non-smoker</option>            {/* Non-smoker = 0 */}
                    <option value="1">Ex-smoker</option>             {/* Ex-smoker = 1 */}
                    <option value="2">Light smoker (less than 10)</option>  {/* Light smoker = 2 */}
                    <option value="3">Moderate smoker (10 to 19)</option>   {/* Moderate smoker = 3 */}
                    <option value="4">Heavy smoker (20 or over)</option>    {/* Heavy smoker = 4 */}
                  </Form.Control>
                  <Form.Text className="text-muted">
                    Please select your options from the dropdown.
                  </Form.Text>

                  {errors.smokingStatus && (
                    <Form.Control.Feedback type="invalid">
                      Smoking status is required.
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group>


              {/* Diabetes */}
              {/* Diabetes Status Field */}
              <Form.Group as={Row} controlId="diabetesStatus" style={fieldStyle}>
                <Form.Label column sm="4" style={labelStyle}>
                  <FaSyringe style={iconStyle} /> Diabetes Status:
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    as="select"
                    {...register('diabetesStatus', { required: true })}
                    // onChange={handleDiabetesChange}  // Handle the change event
                    isInvalid={!!errors.diabetesStatus}
                  >
                    <option value={JSON.stringify({ type1: 0, type2: 0 })}>None</option>
                    <option value={JSON.stringify({ type1: 1, type2: 0 })}>Type 1</option>
                    <option value={JSON.stringify({ type1: 0, type2: 1 })}>Type 2</option>
                  </Form.Control>
                  <Form.Text className="text-muted text-primary">
                    Please select your options from the dropdown.
                  </Form.Text>

                  {errors.diabetesStatus && (
                    <Form.Control.Feedback type="invalid">
                      Diabetes status is required.
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group>

              {/* Checkboxes */}
              <Form.Group style={fieldStyle}>
                <Form.Check
                  type="checkbox"
                  label="Angina or heart attack in a 1st degree relative < 60?"
                  {...register('angina')}
                />
                <Form.Check
                  type="checkbox"
                  label="Chronic kidney disease (stage 3, 4 or 5)?"
                  {...register('chronicKidneyDisease')}
                />
                <Form.Check
                  type="checkbox"
                  label="Atrial fibrillation?"
                  {...register('atrialFibrillation')}
                />
                <Form.Check
                  type="checkbox"
                  label="On blood pressure treatment?"
                  {...register('bloodPressureTreatment')}
                />
                <Form.Check
                  type="checkbox"
                  label="Do you have migraines?"
                  {...register('migraines')}
                />
                <Form.Check
                  type="checkbox"
                  label="Rheumatoid arthritis?"
                  {...register('rheumatoidArthritis')}
                />
                <Form.Check
                  type="checkbox"
                  label="Systemic lupus erythematosus (SLE)?"
                  {...register('sle')}
                />
                <Form.Check
                  type="checkbox"
                  label="Severe mental illness?"
                  {...register('mentalIllness')}
                />
                <Form.Check
                  type="checkbox"
                  label="On atypical antipsychotic medication?"
                  {...register('antipsychoticMedication')}
                />
                <Form.Check
                  type="checkbox"
                  label="On regular steroid tablets?"
                  {...register('steroidTablets')}
                />

                <Form.Check
                  type="checkbox"
                  label="A diagnosis of or treatment for erectile dysfunction?"
                  {...register('erectileDysfunction')} // New field name
                />

              </Form.Group>

              {/* Cholesterol/HDL ratio */}
              <Form.Group as={Row} controlId="cholesterol" style={fieldStyle}>
                <Form.Label column sm="4" style={labelStyle}>
                  Cholesterol/HDL ratio:
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="number"
                    step="0.1"
                    {...register('cholesterol', { min: 1.0, max: 11.0 })}
                    placeholder="Enter a value between 1.0 and 11.0"
                    isInvalid={!!errors.cholesterol}
                  />
                  <Form.Text className="text-muted">
                    Leave blank if you do not know your Cholesterol/HDL ratio.
                  </Form.Text>
                  {errors.cholesterol && (
                    <Form.Control.Feedback type="invalid">
                      Cholesterol/HDL ratio must be between 1.0 and 11.0.
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group>


              {/* Systolic blood pressure */}
              <Form.Group as={Row} controlId="sbp" style={fieldStyle}>
                <Form.Label column sm="4" style={labelStyle}>
                  Systolic blood pressure:
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="number"
                    {...register('sbp', { min: 70, max: 210 })}
                    placeholder="Enter an integer between 70 and 210"
                    isInvalid={!!errors.sbp}
                  />
                  <Form.Text className="text-muted">
                    Leave blank if you do not know your Systolic Blood Pressure.
                  </Form.Text>
                  {errors.sbp && (
                    <Form.Control.Feedback type="invalid">
                      Systolic blood pressure must be between 70 and 210.
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group>

              {/* Standard deviation of SBP */}
              <Form.Group as={Row} controlId="sbpDeviation" style={fieldStyle}>
                <Form.Label column sm="4" style={labelStyle}>
                  Standard deviation of SBP:
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="number"
                    step="0.1"
                    {...register('sbpDeviation', { min: 0.0, max: 40.0 })}
                    placeholder="Enter a value between 0.0 and 40.0"
                    isInvalid={!!errors.sbpDeviation}
                  />
                  <Form.Text className="text-muted">
                    Leave blank if you do not know your Standard Deviation.
                  </Form.Text>
                  {errors.sbpDeviation && (
                    <Form.Control.Feedback type="invalid">
                      Standard deviation must be between 0.0 and 40.0.
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group>



              {/* Height in cm */}
              <Form.Group as={Row} controlId="height" style={fieldStyle}>
                <Form.Label column sm="4" style={labelStyle}>
                  Height (cm):
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="number"
                    {...register('height', { required: true, min: 140, max: 210 })}
                    placeholder="Enter height between 140 and 210 cm"
                    isInvalid={!!errors.height}
                  />
                  {errors.height && (
                    <Form.Control.Feedback type="invalid">
                      Height must be between 140 cm and 210 cm.
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group>

              {/* Weight */}
              <Form.Group as={Row} controlId="weight" style={fieldStyle}>
                <Form.Label column sm="4" style={labelStyle}>
                  <FaWeight style={iconStyle} /> Weight (in kg):
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="number"
                    step="0.1"
                    {...register('weight', { required: true, min: 20, max: 200 })}
                    placeholder="Enter weight in 20kg-200kg"
                    isInvalid={!!errors.weight}
                  />
                  {errors.weight && (
                    <Form.Control.Feedback type="invalid">
                      Weight is required and should be between 20 and 200 kg.
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group>

              <div className='d-flex justify-content-center'>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={handleRecaptchaChange}
                />
              </div>

              {/* Submit Button */}
              {/* Spinner and Loading  */}
              {
                loading ? (
                  <div className=' d-flex justify-content-center my-4'>
                    <Spinner
                      as="span"
                      animation="border"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                      variant="primary"

                    />
                  </div>

                )
                  : (<Button type="submit" style={buttonStyle}>
                    Submit
                  </Button>)

              }


            </Form>
          </div>
        </Col>
      </Row>

      {/* Popup Modal */}
      <Modal show={showPopup} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>QRisk Result</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
          <div className="text-center" >
            {popupContent}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>




    </Container>
  );
}

export default QRiskForm;
