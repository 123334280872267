import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Card, Badge } from 'react-bootstrap';
import { FaGraduationCap, FaEnvelope, FaPhone, FaMapMarkerAlt, FaStar, FaAward, FaSchool } from 'react-icons/fa'; // Import icons from react-icons
import './../App.css';

function UserProfile2({ data, banner, profile }) {
  const [username, setUsername] = useState("");
  const [degree, setDegree] = useState("");
  const [specialization, setSpecialization] = useState([]);
  const [experience, setExperience] = useState("");

  const [collegeName, setCollegeName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [contryCode, setContryCode] = useState("");
  const [address, setAddress] = useState("");
  const [image, setImage] = useState("./pro.jpg");

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      setUsername(data.username ? data.name : "Not provided");
      setDegree(data.qualification ? data.qualification : "Not provided");
      setCollegeName(data.institute_name ? data.institute_name : "Not provided");
      setEmail(data.email.length > 0 ? data.email[0].value : "Not provided");
      setPhone(data.phone.length > 0 ? data.phone[0].value : "Not provided");
      setContryCode(data.phone.length > 0 ? data.phone[0].country_code : "Not provided");
      setSpecialization(data && data.setSpecialization && data.specialization.length>0 ? data.specialization : [])
      setExperience(data.experience ? data.experience : "Not Provided" )
      setImage(data && data.images && data.images.length && data.images[0].value !== "" ? data.images[0].value  : `${process.env.PUBLIC_URL}/profileimages/faceless.png`);
      setAddress(data.address ? data.address : "Not provided");
    } else {
      setUsername("No User");
      setDegree("No User");
      setCollegeName("No User");
      setEmail("No User");
      setPhone("No User");
      setImage("./pro.jpg");
      setAddress("No User");
    }
  }, [data]); // Dependency array includes 'data' to update state whenever 'data' changes.
  return (
    <>
      <Container className="my-5 bg-light p-4 rounded shadow-lg">
        <Row className="justify-content-center align-items-center">
          {/* banner images */}
          {/* <Col xs={12} lg={4} className="text-center mb-4 mb-lg-0">
            <Image src={banner ? banner : `${process.env.PUBLIC_URL}/profileimages/cover-new.png`} roundedCircle thumbnail fluid alt="User Profile" className="profile-img" />
          </Col> */}

          {/* Profile Image */}
          <Col xs={12} lg={4} className="text-center mb-4 mb-lg-0">
            <Image src={profile ? profile : `${process.env.PUBLIC_URL}/profileimages/faceless.png`} roundedCircle thumbnail fluid alt="User Profile" className="profile-img" />
          </Col>
          {/* Personal info */}
          <Col xs={12} lg={6} className="d-flex justify-content-center">
            <Card style={{ backgroundColor: '#fff', color: '#333', padding: '20px', borderRadius: '10px', width: '100%', maxWidth: '350px' }}>
              <Card.Body>
                <Card.Title className='mb-4' style={{fontSize:"25px" ,fontWeight: 'bold', color: '#285AAF' }}>{username}</Card.Title>

                <Card.Text style={{ fontSize: '14px' }}>
                  <FaGraduationCap style={{ marginRight: '10px', color: '#285AAF' }} />
                  <strong>Degree</strong><br />
                  {degree}
                </Card.Text>

                <Card.Text style={{ fontSize: '14px' }}>
                  <FaSchool style={{ marginRight: '10px', color: '#285AAF' }} />
                  <strong>College</strong><br />
                  {collegeName}
                </Card.Text>

                <Card.Text>
                <strong>Specialty</strong><br />
                {specialization.length > 0 ? (
                  specialization.map((specialty, index) => (
                    <Badge key={index} pill bg="info" style={{ marginRight: '5px' }}>
                      {specialty}
                    </Badge>
                  ))
                ) : (
                  <Badge pill bg="secondary">Not Provided</Badge>
                )}
              </Card.Text>


                <Card.Text>
                  <FaAward style={{ marginRight: '10px', color: '#285AAF' }} />
                  <strong >Experience</strong><br />
                  {experience}
                </Card.Text>
                <Card.Text>
                  <FaEnvelope style={{ marginRight: '10px', color: '#285AAF', color: '#285AAF' }} />
                  <strong>Email</strong><br />
                  <span style={{ display: 'inline-block', marginRight: '5px' }}>{email}</span>
                </Card.Text>
                <Card.Text>
                  <FaPhone style={{ marginRight: '10px', color: '#285AAF' }} />
                  <strong>Phone</strong><br />
                  <span style={{ display: 'inline-block', marginRight: '5px' }}>{contryCode}-{phone}</span>
                </Card.Text>
                <Card.Text>
                  <FaMapMarkerAlt style={{ marginRight: '10px', color: '#285AAF' }} />
                  <strong>Address</strong><br />
                  {address}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Container>
    </>
  );
}

export default UserProfile2;
