import React from 'react';
import './../App.css';

function UserProfileCover({coverImageUrl}) {
  return (
    <>
    
      <div className="profile-coverr">
            {/* <img src="./ProfileBanner.png" alt="Cover" className="img-fluid w-100" /> */}
            {/* <img src="https://dalleproduse.blob.core.windows.net/private/images/1ceeb86a-de64-4667-a361-5ae58ee0efb3/generated_00.png?se=2024-08-30T12%3A02%3A55Z&sig=AwZ7gHkL5cALPun2%2Bx7qr6hdV%2Blv9pytn%2FuB7vIRQ7c%3D&ske=2024-08-31T09%3A34%3A53Z&skoid=09ba021e-c417-441c-b203-c81e5dcd7b7f&sks=b&skt=2024-08-24T09%3A34%3A53Z&sktid=33e01921-4d64-4f8c-a055-5bdaffd5e33d&skv=2020-10-02&sp=r&spr=https&sr=b&sv=2020-10-02" alt="Cover" className="img-fluid w-100" /> */}
            {/* <img src="https://dalleproduse.blob.core.windows.net/private/images/1ceeb86a-de64-4667-a361-5ae58ee0efb3/generated_00.png?se=2024-08-30T12%3A02%3A55Z&sig=AwZ7gHkL5cALPun2%2Bx7qr6hdV%2Blv9pytn%2FuB7vIRQ7c%3D&ske=2024-08-31T09%3A34%3A53Z&skoid=09ba021e-c417-441c-b203-c81e5dcd7b7f&sks=b&skt=2024-08-24T09%3A34%3A53Z&sktid=33e01921-4d64-4f8c-a055-5bdaffd5e33d&skv=2020-10-02&sp=r&spr=https&sr=b&sv=2020-10-02" alt="Cover" className="img-fluid w-100" /> */}
            <img src={coverImageUrl} alt="Cover" className="cover-imagee img-fluid w-100" />
            {/* <img src="./cover-new-2.png" alt="Cover" className="img-fluid w-100" /> */}
        </div>
    </>
  )
}

export default UserProfileCover