import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { Button, ToggleButton, ToggleButtonGroup, InputGroup, FormControl, Card, Spinner } from 'react-bootstrap';
import { Auth } from 'aws-amplify';

const NOTSIGNIN = 'You are NOT logged in';
const SIGNEDIN = 'You have logged in successfully';
const SIGNEDOUT = 'You have logged out successfully';
const WAITINGFOROTP = 'Enter OTP number';
const VERIFYNUMBER = 'Verifying number (Country code +XX needed)';

function AuthWithToggle() {
  const [message, setMessage] = useState('Welcome User');
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [loginMethod, setLoginMethod] = useState('email'); 
  const [loading, setLoading] = useState(false); // New loading state
  const password = Math.random().toString(10) + 'Abc#';
  const navigate = useNavigate(); 

  // style Constants 
  const activeStyle = {
    backgroundColor: '#5151E5',
    borderColor: '#5151E5',
    color: '#ffffff', 
  };
  
  const inactiveStyle = {
    backgroundColor: 'transparent',
    borderColor: '#5151E5',
    color: '#5151E5', 
  };

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userData"))
    if(userData){
      navigate('/profileForm')
    }
  }, []);

  const signIn = () => {
    const verificationMessage = loginMethod === 'email'
      ? 'Verifying email address...'
      : 'Verifying mobile number (Country code +XX needed)';
    setMessage(verificationMessage);
    setLoading(true); // Start loading

    Auth.signIn(email)
      .then((result) => {
        setSession(result);
        setMessage(WAITINGFOROTP);
        setLoading(false); // Stop loading
      })
      .catch((e) => {
        setLoading(false); // Stop loading
        if (e.code === 'UserNotFoundException') {
          signUp();
        } else if (e.code === 'UsernameExistsException') {
          setMessage(WAITINGFOROTP);
        } else {
          console.error(e);
        }
      });
  };

  // const signUp = async () => {
  //   try {
  //     await Auth.signUp({
  //       username: email,
  //       password,
  //       attributes: {
  //         email,
  //       },
  //     });
  //     signIn();
  //   } catch (err) {
  //     console.error("--------------------errp")
  //     console.error(err);
  //   }
  // };
  const signUp = async () => {

    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let attributes = {};

    if (emailRegex.test(email)) {
      attributes.email = email; // User input is an email
    } else {
      attributes.phone_number = email; // Assume input is a phone number
    }


    try {
      await Auth.signUp({
        username: email,
        password,
        attributes
      });
      signIn();
    } catch (err) {
      console.error(err);
    }
  };
  const verifyOtp = () => {
    setLoading(true); // Start loading
    Auth.sendCustomChallengeAnswer(session, otp)
      .then((user) => {
        setUser(user);
        setMessage(SIGNEDIN);
        setSession(null);
        const userData = {
          username: user.username,
          email: user.attributes.email,
          idToken: user.signInUserSession.idToken.jwtToken
        };
        localStorage.setItem('userData', JSON.stringify(userData));
        navigate('/profileform');
      })
      .catch((err) => {
        setLoading(false); // Stop loading
        setMessage(err.message);
        setOtp('');
        console.error(err);
      });
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-200">
      <header className="App-header">
        <Card className="mt-3 shadow-lg" style={{ maxWidth: '800px', width: '100%', padding: "50px" }}>
          <p className="text-center" style={{
              color: '#5151E5',
              backgroundColor: 'transparent',
              padding: '10px',
              borderRadius: '8px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
              fontWeight: 'bold',
              letterSpacing: '0.5px',
              border: '1px solid #5151E5'
            }}>
            {message}
          </p>

          <ToggleButtonGroup
            type="radio"
            name="login-method"
            value={loginMethod}
            onChange={(val) => setLoginMethod(val)}
            className="d-flex justify-content-center my-4"
          >
            <ToggleButton
              id="email-login"
              value="email"
              className="mx-2"
              style={loginMethod === 'email' ? activeStyle : inactiveStyle}
            >
              Email Login
            </ToggleButton>
            <ToggleButton
              id="mobile-login"
              value="mobile"
              className="mx-1"
              style={loginMethod === 'mobile' ? activeStyle : inactiveStyle}
            >
              Mobile Login
            </ToggleButton>
          </ToggleButtonGroup>

          {!user && !session && (
            <div>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder={loginMethod === 'email' ? 'Enter your email' : 'Enter your mobile (+91)'}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </InputGroup>
              <Button style={{ "background": "#5151E5" }} onClick={signIn} className="w-100 my-2">
                Get OTP
              </Button>
            </div>
          )}

          {!user && session && (
            <div>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Enter OTP"
                  onChange={(event) => setOtp(event.target.value)}
                  value={otp}
                />
              </InputGroup>
              <Button style={{ "background": "#5151E5" }} onClick={verifyOtp} className="w-100">
                Confirm OTP
              </Button>
            </div>
          )}

          {/* Loader */}
          {loading && (
            <div className="text-center my-3">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </Card>
      </header>
    </div>
  );
}

export default AuthWithToggle;
