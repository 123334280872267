import React from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './../App.css';
function UserProfileBlogs({ blog }) {


  const navigate = useNavigate();

  // // Handle Redirect to blogs
  function handleRedirect(url) {
    window.open(url, '_blank')
  }

  function handleMoreContent(){
    navigate('/blogs')
  }

  return (
    <>
      <Card 
      className="mb-3 border border-info"
      >
        <div className="row no-gutters">


          {/* Left side: Image */}
          <div className="col-12 col-md-4 d-flex align-items-center justify-content-center">
            <div className="rounded overflow-hidden" style={{ width: '100%', maxWidth: '110px', height: 'auto' }}>
              <img
                src={`${process.env.PUBLIC_URL}/profileimages/logo.jpeg`}
                // src={blog.Image_Url}
                alt="Blog"
                className="img-fluid"
                style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
              />
            </div>
          </div>

          {/* Right side: Content */}
          <div className="col-12 col-md-8 d-flex flex-column justify-content-between p-3">
            <div>
              <h5 className="mb-2">{blog.Title}</h5>
              <p className="text-muted mb-2">{blog.Summary}</p>
            </div>
            <div className='d-flex align-items-center gap-2 mt-2 '>
              <button className="btn btn-sm  text-light align-self-start" style={{ background: "#285AAF", boxShadow: "0 2px 4px rgba(40, 90, 175, 0.4)" }} onClick={() => handleRedirect(blog.URL)}>
                Read More
              </button>
              <button className="btn btn-sm  text-light align-self-start bg-success" style={{ boxShadow: "0 2px 4px rgba(40, 90, 175, 0.4)" }} onClick={handleMoreContent}>
                More Content
              </button>
              <p className="mb-3">   <span className="badge bg-secondary rounded-pill p-2">
                Date: {blog.Date}
              </span></p>
            </div>

          </div>
        </div>
      </Card>

    </>
  )
}

export default UserProfileBlogs