import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

function UserProfileTab({ activeTab, onTabChange }) {
  return (
    <>
      <Tabs
        id="profile-tabs"
        activeKey={activeTab}
        onSelect={(k) => onTabChange(k)}
        className="mb-3"
      >
        <Tab 
          eventKey="media" 
          title={
            <span 
              style={{ 
                color: activeTab === 'media' ? '#285AAF'  :'#333',  // Change color if active
                fontWeight: activeTab === 'media' ? 'bold' : 'normal', // Make active bold
              }}
            >
              Content
            </span>
          }
        >
          {/* Content for Media */}
        </Tab>

        <Tab 
          eventKey="tweets-replies" 
          title={
            <span 
              style={{ 
                color: activeTab === 'tweets-replies' ? '#285AAF'  :'#333', 
                fontWeight: activeTab === 'tweets-replies' ? 'bold' : 'normal',
              }}
            >
              Career
            </span>
          }
        >
          {/* Content for Career */}
        </Tab>

        <Tab 
          eventKey="tweets" 
          title={
            <span 
              style={{ 
                color: activeTab === 'tweets' ? '#285AAF'  :'#333' , 
                fontWeight: activeTab === 'tweets' ? 'bold' : 'normal',
              }}
            >
              Conference
            </span>
          }
        >
          {/* Content for Conference */}
        </Tab>
      </Tabs>
    </>
  );
}

export default UserProfileTab;
