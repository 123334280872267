import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import TagManager from 'react-gtm-module';

import { Amplify } from 'aws-amplify';
import awsmobile from './aws-exports';

try {
  Amplify.configure(awsmobile);
} catch (error) {
  console.error(error);
}

try {
  const tagManagerArgs = {
    gtmId: 'GTM-XXXXXX' // Replace with your GTM ID
  };

  TagManager.initialize(tagManagerArgs);
}
catch { console.log("google tag error") }



// *******Amplify configuring************
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
