import React from 'react';
function Home() {

  return (
  <>
   <h1>Home Component</h1>
  </>
  )
}

export default Home