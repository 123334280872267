
// ****Requirement****
// creating the doctors profile using the form 
// skip option not all fields mandatory 
// fields - name, email, phone, college, degree, ProfileImage

// ***code logic**
// all the states 
// handler function 
// api call 
// redirect to the page 
// local storage? 
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ProfileForm from './../Components/ProfileForm';
import './../App.css';


function ProfileCreationForm() {
    return (
        <>
            <Container fluid className="d-flex flex-column">
                <Row className="flex-grow-1">
                    
                    <Col md={6} className="d-none d-md-flex justify-content-center align-items-center login-image">
                        <div className="text-center text-white">
                            <h1 className="mb-4">Create Your Profile!</h1>
                            <p className="mb-5">  Set up your profile now, or skip and do it later.</p>
                            {/* <img src="./avatar-1.png" alt="Dashboard Screenshot" className="img-fluid" style={{height:"400px"}} /> */}
                        </div>
                    </Col>


                    <Col md={6} className="d-flex justify-content-center align-items-center bg-light">
                        <ProfileForm />
                    </Col>


                </Row>
            </Container>
        </>
    )
}

export default ProfileCreationForm